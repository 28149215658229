export class UserMetadataConstants {
    static UserId = 'userId';
    static UserMetadataKey = 'userMetadataKey';
    static UserClassIds = 'userClassIds';
    static LoggedInUserClassIds = 'loggedInUserClassIds';
    static UserClassCount = 'userClassCount';
    static PastLoggedInUsers = 'pastLoggedInUsers';
    static StudentId = 'studentId';
    static TeacherId = 'teacherId';
    static ClassId = 'classId';
    static UserGroupId = 'userGroupId';
    static UserGroupOrganizationId = 'userGroupOrganizationId';
    static UserGroupOrganizationName = 'userGroupOrganizationName';
    static UserGroupOrganizationStatus = 'userGroupOrganizationStatus';
    static SchoolId = 'schoolId';
    static SchoolAndGrade = 'schoolAndGrade';
    static FirstName = 'firstName';
    static LastName = 'lastName';
    static AvatarImageUrl = 'avatarImageUrl';
    static AvatarImageStatus = 'avatarImageStatus';
    static LexileRating = 'lexileRating';
    static UserName = 'userName';
    static CurrentGrade = 'currentGrade';
    static PremiumUser = 'premiumUser';
    static OfflineWaitTime = 'offlineWaitTime';
    static StudentClassId = 'studentClassId';
    static QueueId = 'queueId';
    static StudentPlacementStatus = 'studentPlacementStatus';
    static StudentPlacementTestId = 'studentPlacementTestId';
    static TeacherClassId = 'teacherClassId';
    static ProviderTypes = 'providerTypes';
    static BookUrl = 'bookUrl';
    static ReaderBook = 'readerBook';
    static BorrowedBookId = 'borrowedBookId';
    static BookCoverUrl = 'bookCoverUrl';
    static TeacherCanSwitchTab = 'teacherCanSwitchTab';
    static TeacherCanCloseTab = 'teacherCanCloseTab';
    static LexileThresholds = 'lexileThresholds';
    static QueuePriority = 'queuePriority';
    static QueuePath = 'queuePath';
    static TrialStatus = 'trialStatus';
    static OfflineSessionId = 'offlineSessionId';
    static ReadingTagId = 'readingTagId';
    static Grade_readingTagId_classificationId = 'grade_readingTagId_classificationId';
    static Grade_classificationId = 'grade_classificationId';
    static Grade_classificationId_subStandardId = 'grade_classificationId_subStandardId';
    static CommonCoreStandardId = 'ccStandardId';
    static CommonCoreSubStandardId = 'commonCoreSubStandardId';
    static InactivityTimeoutInSeconds = 'inactivityTimeoutInSeconds';
    static InactivityTimeoutWarningInSeconds = 'inactivityTimeoutWarningInSeconds';
    static InactivityTimeoutForReaderInSeconds = 'inactivityTimeoutForReaderInSeconds';
    static InactivityTimeoutWarningForReaderInSeconds = 'inactivityTimeoutWarningForReaderInSeconds';
    static RequestFeedback = 'requestFeedback';
    static BookIdForAssessments = 'bookIdForAssessments';
    static BookAssessments = 'bookAssessments';
    static AssessmentAnswers = 'assessmentAnswers';
    static ClozeThresholds = 'clozeThresholds';
    static SrqThresholds = 'srqThresholds';
    static MrqThresholds = 'mrqThresholds';
    static DailyMinutesReadThresholds = 'dailyMinutesReadThresholds';
    static SrqAndMcqAggregateThresholds = 'srqAndMcqAggregateThresholds';
    static SrqDisplayPercentageThresholds = 'srqDisplayPercentageThresholds';
    static DefinitionLookup = 'definitionLookup';
    static BookIdForAssessmentAnswers = 'bookIdForAssessmentAnswers';
    static BookIdForDefineWords = 'bookIdForDefineWords';
    static LastPCQuestion = 'LastPCQuestion';
    static UtcOffset = 'utcOffset';
    static HasSeries = 'hasSeries';
    static BookId = 'bookId';
    static DistrictId = 'districtId';
    static CustomerId = 'customerId';
    static MissedClozeAssessments = 'missedClozeAssessments';
    static ObserverStudents = 'observerStudents';
    static HasUsedWordWork = 'hasUsedWordWorked';
    static ClassSource = 'classSource';
    static RubricId = 'rubricId';
    static GiftGoalsByStudent = 'giftGoals';
    static CurrentAcademicYear = 'currentAcademicYear';
    static SelectedAcademicYearId = 'selectedAcademicYearId';
    static SelectedAcademicYear = 'selectedAcademicYear';
    static DemoSyntaxWords = 'demoSyntaxWords';
    static DemoTopicQuestions = 'demoTopicQuestions';
    static CBStudentPortfolio = 'CBStudentPortfolio';
    static LexileLastUpdateDate = 'lexileLastUpdateDate';
    static AllowPowerChallengeOutsideSchoolHours = 'allowPowerChallengeOutsideSchoolHours';
    static DelayPowerChallengeUntilDate = 'delayPowerChallengeUntilDate';
    static PowerChallengeGradeLevel = 'powerChallengeGradeLevel';
    static State = 'state';
    static IsEcommerceAdmin = 'IsEcommerceAdmin';
    static PasswordRequiredChange = 'passwordRequiredChange';
    static LexileCssClassForJustCompletedPC = 'lexileCssClassForJustCompletedPC';
    static isInfluencer = 'isInfluencer';
    static CountryId = 'countryId';
    static HeaderColor = 'headerColor';
    static ReadingCoachSchoolIds = 'readingCoachSchoolIds';
    static EnglishLanguageLearner = 'englishLanguageLearner';
}
