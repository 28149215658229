//Inspired from ng2-device-detector
export class DeviceConstants {
    static DEVICES: any = {
        ANDROID: "android",
        ANDROID_TAB: "android-tab",
        I_PAD: "ipad",
        IPHONE: "iphone",
        I_POD: "ipod",
        NEXUS_MOBILE: "nexus-mobile",
        NEXUS_TABLET: "nexus-tablet",
        BLACKBERRY: "blackberry",
        FIREFOX_OS: "firefox-os",
        CHROME_BOOK: "chrome-book",
        WINDOWS_PHONE: "windows-phone",
        PS4: "ps4",
        VITA: "vita",
        CHROMECAST: "chromecast",
        APPLE_TV: "apple-tv",
        GOOGLE_TV: "google-tv",
        WINDOWS: "windows-desktop",
        UNKNOWN: "unknown"
    };

    static DEVICES_RE: any = {
        ANDROID: {and: [/\bAndroid\b/, /\bMobile\b/, {not: /Windows Phone/}]},
        ANDROID_TAB: {and: [/\bAndroid\b/, {not: [/Windows Phone/, /\bMobile\b/]}]},
        I_PAD: /\biPad\b/,
        IPHONE: {and: [/\biPhone\b/, {not: /Windows Phone/}]},
        I_POD: /\biPod\b/,
        NEXUS_MOBILE: {and: [/\bNexus\b/, /\bMobile\b/]},
        NEXUS_TABLET: {and: [/\bNexus\b/, {not: /Mobile/}]},
        BLACKBERRY: /\bblackberry\b/,
        FIREFOX_OS: {and: [/\bFirefox\b/, /\bMobile\b/]},
        CHROME_BOOK: /\bCrOS\b/,
        WINDOWS_PHONE: {or: [/\bIEMobile\b/, /\bWindows Phone\b/]},
        PS4: /\bMozilla\/5.0 \(PlayStation 4\b/,
        CHROMECAST: /\bCrKey\b/,
        APPLE_TV: /^iTunes-AppleTV\/4.1$/,
        GOOGLE_TV: /\bGoogleTV\b/,
        VITA: /\bMozilla\/5.0 \(Play(S|s)tation Vita\b/,
        WINDOWS: /\bWindows (?!Phone)/
    };
    static DEVICETYPE: any = {
        PHONE: "phone",
        TABLET: "tablet",
        DESKTOP: "desktop",
        UNKNOWN: "unknown"
    };

}