export class BadgeAsset {
    static Badge_Completed_EN = './assets/images/badge-library/Badge_Completed_en.svg';
    static Badge_Completed_ES = './assets/images/badge-library/Badge_Completed_es.svg';
    static Badge_Completed_HE = './assets/images/badge-library/Badge_Completed_he.svg';
    static Badge_PowerTexts_EN = './assets/images/badge-library/Badge_PowerTexts_en.svg';
    static Badge_PowerTexts_ES = './assets/images/badge-library/Badge_PowerTexts_es.svg';
    static Badge_ReadingPower_EN = './assets/images/badge-library/Badge_ReadingPower_en.svg';
    static Badge_ReadingPower_ES = './assets/images/badge-library/Badge_ReadingPower_es.svg';
    static Badge_BeatTheClock_EN = './assets/images/badge-library/Badge_BeatTheClock_en.svg';
    static Badge_BeatTheClock_ES = './assets/images/badge-library/Badge_BeatTheClock_es.svg';
    static Badge_MillionWordsRead_EN = './assets/images/badge-library/Badge_MillionWordsRead_en.svg';
    static Badge_MillionWordsRead_ES = './assets/images/badge-library/Badge_MillionWordsRead_es.svg';
    static Badge_MillionWordsRead_HE = './assets/images/badge-library/Badge_MillionWordsRead_he.svg';
    static Badge_Milestone_EN = './assets/images/badge-library/Badge_Milestone_en.svg';
    static Badge_Milestone_ES = './assets/images/badge-library/Badge_Milestone_es.svg';
    static Badge_ShortResponseAbove_EN = './assets/images/badge-library/Badge_ShortResponseAbove_en.svg';
    static Badge_ShortResponseAbove_ES = './assets/images/badge-library/Badge_ShortResponseAbove_es.svg';
    static Badge_ShortResponseAbove_HE = './assets/images/badge-library/Badge_ShortResponseAbove_he.svg';
    static Badge_ShortResponseOn_EN = './assets/images/badge-library/Badge_ShortResponseOn_en.svg';
    static Badge_ShortResponseOn_ES = './assets/images/badge-library/Badge_ShortResponseOn_es.svg';
    static Badge_ShortResponseOn_HE = './assets/images/badge-library/Badge_ShortResponseOn_he.svg';
    static Badge_ActionAdventure_EN = './assets/images/badge-library/Badge_ActionAdventure_en.svg';
    static Badge_ActionAdventure_ES = './assets/images/badge-library/Badge_ActionAdventure_es.svg';
    static Badge_Adventure_EN = './assets/images/badge-library/Badge_Adventure_en.svg';
    static Badge_Adventure_ES = './assets/images/badge-library/Badge_Adventure_en.svg';
    static Badge_VocabClozes_EN = './assets/images/badge-library/Badge_VocabClozes_en.svg';
    static Badge_VocabClozes_ES = './assets/images/badge-library/Badge_VocabClozes_es.svg';
    static Badge_VocabClozes_HE = './assets/images/badge-library/Badge_VocabClozes_he.svg';
    static Badge_VocabGenius_EN = './assets/images/badge-library/Badge_VocabGenius_en.svg';
    static Badge_VocabGenius_ES = './assets/images/badge-library/Badge_VocabGenius_es.svg';
    static Badge_VocabGenius_HE = './assets/images/badge-library/Badge_VocabGenius_he.svg';
    static Badge_AllAbout_EN = './assets/images/badge-library/Badge_AllAbout_en.svg';
    static Badge_AllAbout_ES = './assets/images/badge-library/Badge_AllAbout_es.svg';
    static Badge_Anthologies_EN = './assets/images/badge-library/Badge_Anthologies_en.svg';
    static Badge_Anthologies_ES = './assets/images/badge-library/Badge_Anthologies_es.svg';
    static Badge_Article_EN = './assets/images/badge-library/Badge_Article_en.svg';
    static Badge_Article_ES = './assets/images/badge-library/Badge_Article_es.svg';
    static Badge_Autobiography_EN = './assets/images/badge-library/Badge_Autobiography_en.svg';
    static Badge_Autobiography_ES = './assets/images/badge-library/Badge_Autobiography_es.svg';
    static Badge_Biography_EN = './assets/images/badge-library/Badge_Biography_en.svg';
    static Badge_Biography_ES = './assets/images/badge-library/Badge_Biography_es.svg';
    static Badge_Classics_EN = './assets/images/badge-library/Badge_Classics_en.svg';
    static Badge_Classics_ES = './assets/images/badge-library/Badge_Classics_es.svg';
    static Badge_Drama_EN = './assets/images/badge-library/Badge_Drama_en.svg';
    static Badge_Drama_ES = './assets/images/badge-library/Badge_Drama_es.svg';
    static Badge_Essays_EN = './assets/images/badge-library/Badge_Essays_en.svg';
    static Badge_Essays_ES = './assets/images/badge-library/Badge_Essays_es.svg';
    static Badge_Fairytales_EN = './assets/images/badge-library/Badge_Fairytales_en.svg';
    static Badge_Fairytales_ES = './assets/images/badge-library/Badge_Fairytales_es.svg';
    static Badge_Fantasy_EN = './assets/images/badge-library/Badge_Fantasy_en.svg';
    static Badge_Fantasy_ES = './assets/images/badge-library/Badge_Fantasy_es.svg';
    static Badge_FolktalesFables_EN = './assets/images/badge-library/Badge_FolktalesFables_en.svg';
    static Badge_FolktalesFables_ES = './assets/images/badge-library/Badge_FolktalesFables_es.svg';
    static Badge_Geography_EN = './assets/images/badge-library/Badge_Geography_en.svg';
    static Badge_Geography_ES = './assets/images/badge-library/Badge_Geography_es.svg';
    static Badge_GraphicNovels_EN = './assets/images/badge-library/Badge_GraphicNovels_en.svg';
    static Badge_GraphicNovels_ES = './assets/images/badge-library/Badge_GraphicNovels_es.svg';
    static Badge_HistoricalFiction_EN = './assets/images/badge-library/Badge_HistoricalFiction_en.svg';
    static Badge_HistoricalFiction_ES = './assets/images/badge-library/Badge_HistoricalFiction_es.svg';
    static Badge_History_EN = './assets/images/badge-library/Badge_History_en.svg';
    static Badge_History_ES = './assets/images/badge-library/Badge_History_es.svg';
    static Badge_Humor_EN = './assets/images/badge-library/Badge_Humor_en.svg';
    static Badge_Humor_ES = './assets/images/badge-library/Badge_Humor_es.svg';
    static Badge_LegendsMyths_EN = './assets/images/badge-library/Badge_LegendsMyths_en.svg';
    static Badge_LegendsMyths_ES = './assets/images/badge-library/Badge_LegendsMyths_es.svg';
    static Badge_Mathematics_EN = './assets/images/badge-library/Badge_Mathematics_en.svg';
    static Badge_Mathematics_ES = './assets/images/badge-library/Badge_Mathematics_es.svg';
    static Badge_Mystery_EN = './assets/images/badge-library/Badge_Mystery_en.svg';
    static Badge_Mystery_ES = './assets/images/badge-library/Badge_Mystery_es.svg';
    static Badge_PictureBooks_EN = './assets/images/badge-library/Badge_PictureBooks_en.svg';
    static Badge_PictureBooks_ES = './assets/images/badge-library/Badge_PictureBooks_es.svg';
    static Badge_Poetry_EN = './assets/images/badge-library/Badge_Poetry_en.svg';
    static Badge_Poetry_ES = './assets/images/badge-library/Badge_Poetry_es.svg';
    static Badge_RealisticFiction_EN = './assets/images/badge-library/Badge_RealisticFiction_en.svg';
    static Badge_RealisticFiction_ES = './assets/images/badge-library/Badge_RealisticFiction_es.svg';
    static Badge_Romance_EN = './assets/images/badge-library/Badge_Romance_en.svg';
    static Badge_Romance_ES = './assets/images/badge-library/Badge_Romance_es.svg';
    static Badge_Science_EN = './assets/images/badge-library/Badge_Science_en.svg';
    static Badge_Science_ES = './assets/images/badge-library/Badge_Science_es.svg';
    static Badge_ScienceFiction_EN = './assets/images/badge-library/Badge_ScienceFiction_en.svg';
    static Badge_ScienceFiction_ES = './assets/images/badge-library/Badge_ScienceFiction_es.svg';
    static Badge_SelfHelp_EN = './assets/images/badge-library/Badge_SelfHelp_en.svg';
    static Badge_SelfHelp_ES = './assets/images/badge-library/Badge_SelfHelp_es.svg';
    static Badge_Series_EN = './assets/images/badge-library/Badge_Series_en.svg';
    static Badge_Series_ES = './assets/images/badge-library/Badge_Series_es.svg';
    static Badge_Series_HE = './assets/images/badge-library/Badge_Series_he.svg';
    static Badge_ShortStories_EN = './assets/images/badge-library/Badge_ShortStories_en.svg';
    static Badge_ShortStories_ES = './assets/images/badge-library/Badge_ShortStories_es.svg';
    static Badge_Spanish_EN = './assets/images/badge-library/Badge_Spanish_en.svg';
    static Badge_Spanish_ES = './assets/images/badge-library/Badge_Spanish_es.svg';
    static Badge_Speeches_EN = './assets/images/badge-library/Badge_Speeches_en.svg';
    static Badge_Speeches_ES = './assets/images/badge-library/Badge_Speeches_es.svg';
    static Badge_ThrillerSuspense_EN = './assets/images/badge-library/Badge_ThrillerSuspense_en.svg';
    static Badge_ThrillerSuspense_ES = './assets/images/badge-library/Badge_ThrillerSuspense_es.svg';
    static Badge_WordWork_EN = './assets/images/badge-library/Badge_Word_Work_en.svg';
    static Badge_WordWork_ES = './assets/images/badge-library/Badge_Word_Work_es.svg';
    static Badge_Participation = './assets/images/badge-library/Badge_Participation.svg';
    static Badge_Top_Reader = './assets/images/badge-library/Badge_Top_Reader.svg';
    static Badge_SuperSaver_EN = 'assets/images/badge-library/Badge_Financial_SuperSaver_en.svg';
    static Badge_SuperSaver_ES = 'assets/images/badge-library/Badge_Financial_SuperSaver_es.svg';
    static Badge_FinancialSmarts_EN = 'assets/images/badge-library/Badge_FinancialSmarts_en.svg';
    static Badge_FinancialSmarts_ES = 'assets/images/badge-library/Badge_FinancialSmarts_es.svg';
    static Badge_MoneyManager_EN = 'assets/images/badge-library/Badge_Financial_MoneyManager_en.svg';
    static Badge_MoneyManager_ES = 'assets/images/badge-library/Badge_Financial_MoneyManager_es.svg';
    static Spelling_Bee_Rolling = 'assets/images/badge-library/Badge_Spelling_Bee_1.svg';
    static Spelling_Bee_Retake = 'assets/images/badge-library/Badge_Spelling_Redo_Roll_3_Text.svg';
    static Spelling_Bee_Quiz_Completion = 'assets/images/badge-library/Badge_Spelling_Bee_Quiz_Completion.svg';
    static Badge_Milestones_Minutes_Read = 'assets/images/badge-library/Badge_Milestone_Minutes_Read.svg';
    static Badge_Milestones_Books_Read = 'assets/images/badge-library/Badge_Milestone_Books_Read.svg';
    static Badge_Tournament_Participant = 'assets/images/badge-library/Badge_Tournament1.svg';
    static Badge_Tournament_2 = 'assets/images/badge-library/Badge_Tournament2.svg';
    static Badge_Tournament_3 = 'assets/images/badge-library/Badge_Tournament3.svg';
    static Badge_Tournament_4 = 'assets/images/badge-library/Badge_Tournament4.svg';
    static Badge_Tournament_Winner = 'assets/images/badge-library/Badge_Tournament5.svg';
    static Badge_Tournament_6 = 'assets/images/badge-library/Badge_Tournament6.svg';
    static Badge_Tournament_7 = 'assets/images/badge-library/Badge_Tournament7.svg';
    static Badge_Punctuation_Roll = 'assets/images/badge-library/Badge_Punctuation_Roll_en.svg';
    static Badge_Parts_Of_Speech_Roll = 'assets/images/badge-library/Badge_Parts_Of_Speech_Roll_en.svg';
    static Badge_Syllable_Roll = 'assets/images/badge-library/Badge_Syllable_Roll_en.svg';
    static Badge_Team_Reading_Above_Target = 'assets/images/badge-library/Above_Target_Badge.svg';
    static Badge_Team_Reading_On_Target = 'assets/images/badge-library/On_Target_Badge.svg';
    static Badge_Team_Reading_Participation = 'assets/images/badge-library/Participation_Badge.svg';
    static Badge_Word_Mastery_Roll = 'assets/images/badge-library/Badge_Word_Mastery_Roll.svg';
    static Badge_Contraction_Roll = 'assets/images/badge-library/grammar-rule-badges/Badge_Contraction_Roll_en.svg'
    static Badge_Homophone_Roll = 'assets/images/badge-library/grammar-rule-badges/Badge_Homophone_Roll_en.svg'
    static Badge_Irregular_Verb_Roll = 'assets/images/badge-library/grammar-rule-badges/Badge_Irregular-Verb_Roll_en.svg'
    static Badge_To_Be_Roll = 'assets/images/badge-library/grammar-rule-badges/Badge_To_Be_Roll_en.svg'
    static ContractionsBeginner_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/ContractionsBeginner_Mastery_en.png'
    static ContractionsIntermediate_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/ContractionsIntermediate_Mastery_en.png'
    static ContractionsAdvanced_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/ContractionsAdvanced_Mastery_en.png'
    static ContractionsAdvancedPlus_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/ContractionsAdvancedPlus_Mastery_en.png'
    static HomophonesBeginner_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/HomophonesBeginner_Mastery_en.png'
    static HomophonesIntermediate_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/HomophonesIntermediate_Mastery_en.png'
    static HomophonesAdvanced_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/HomophonesAdvanced_Mastery_en.png'
    static HomophonesAdvancedPlus_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/HomophonesAdvancedPlus_Mastery_en.png'
    static ToBeVerbsBeginner_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/ToBeVerbsBeginner_Mastery_en.png'
    static ToBeVerbsIntermediate_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/ToBeVerbsIntermediate_Mastery_en.png'
    static ToBeVerbsAdvanced_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/ToBeVerbsAdvanced_Mastery_en.png'
    static ToBeVerbsAdvancedPlus_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/ToBeVerbsAdvancedPlus_Mastery_en.png'
    static IrregularPastTenseBeginner_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/IrregularPastTenseBeginner_Mastery_en.png'
    static IrregularPastTenseIntermediate_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/IrregularPastTenseIntermediate_Mastery_en.png'
    static IrregularPastTenseAdvanced_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/IrregularPastTenseAdvanced_Mastery_en.png'
    static IrregularPastTenseAdvancedPlus_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/IrregularPastTenseAdvancedPlus_Mastery_en.png'
    static PunctuationBeginner_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/PunctuationBeginner_Mastery_en.png'
    static PunctuationIntermediate_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/PunctuationIntermediate_Mastery_en.png'
    static PunctuationAdvanced_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/PunctuationAdvanced_Mastery_en.png'
    static PunctuationAdvancedPlus_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/PunctuationAdvancedPlus_Mastery_en.png'
    static PartsOfSpeechBeginner_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/PartsOfSpeechBeginner_Mastery_en.png'
    static PartsOfSpeechIntermediate_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/PartsOfSpeechIntermediate_Mastery_en.png'
    static PartsOfSpeechAdvanced_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/PartsOfSpeechAdvanced_Mastery_en.png'
    static PartsOfSpeechAdvancedPlus_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/PartsOfSpeechAdvancedPlus_Mastery_en.png'
    static NumberOfSyllablesBeginner_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/NumberOfSyllablesBeginner_Mastery_en.png'
    static NumberOfSyllablesIntermediate_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/NumberOfSyllablesIntermediate_Mastery_en.png'
    static NumberOfSyllablesAdvanced_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/NumberOfSyllablesAdvanced_Mastery_en.png'
    static NumberOfSyllablesAdvancedPlus_Mastery_EN = 'assets/images/badge-library/grammar-rule-badges/NumberOfSyllablesAdvancedPlus_Mastery_en.png'
    static Default_Badge = 'assets/images/badge-library/Default_Badge.svg';
}
