import { BadgeColorType } from '../../../projects/mindplay/src/app/core/enums/badge-color-type';
import { IMultiSelectOption } from '../models/i-multi-select-option';
import { ThresholdLevelColorConstants } from './threshold-level-color.constants';

export enum ThresholdLevel {
    BELOW_STANDARD = 0,
    APPROACHING_STANDARD,
    ON_STANDARD,
    ABOVE_STANDARD,
    UNKNOWN
}
export namespace ThresholdLevel {
    export function toList(): IMultiSelectOption[] {
        return [{
            id: ThresholdLevel.BELOW_STANDARD,
            name: `GRADER.GRADE.BELOW`,
            classes: 'below'
        }, {
            id: ThresholdLevel.APPROACHING_STANDARD,
            name: `GRADER.GRADE.APPROACHING`,
            classes: 'approaching'
        }, {
            id: ThresholdLevel.ON_STANDARD,
            name: `GRADER.GRADE.ON`,
            classes: 'on'
        }, {
            id: ThresholdLevel.ABOVE_STANDARD,
            name: `GRADER.GRADE.ABOVE`,
            classes: 'above'
        }];
    }
    export function getColor(thresholdLevel: ThresholdLevel): string {
        switch (thresholdLevel) {
            case ThresholdLevel.ABOVE_STANDARD:
                return ThresholdLevelColorConstants.AboveStandard;
            case ThresholdLevel.ON_STANDARD:
                return ThresholdLevelColorConstants.OnStandard;
            case ThresholdLevel.APPROACHING_STANDARD:
                return ThresholdLevelColorConstants.ApproachingStandard;
            case ThresholdLevel.BELOW_STANDARD:
                return ThresholdLevelColorConstants.BelowStandard;
            default:
                return ThresholdLevelColorConstants.Unknown;
        }
    }

  export function getBadgeType(thresholdLevel: ThresholdLevel): BadgeColorType {
    switch (thresholdLevel) {
      case ThresholdLevel.BELOW_STANDARD:
        return BadgeColorType.BelowStandard;
      case ThresholdLevel.APPROACHING_STANDARD:
        return BadgeColorType.ApproachingStandard;
      case ThresholdLevel.ON_STANDARD:
        return BadgeColorType.OnStandard;
      case ThresholdLevel.ABOVE_STANDARD:
        return BadgeColorType.AboveStandard;
      default:
        return BadgeColorType.Unknown;
    }
  }
}
