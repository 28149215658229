import {BadgeCategory, BadgeType} from '../enums';
import {BadgesInfoItem} from '../models';
import {BadgeAsset} from './badge-asset';
import {BadgeNameConstants} from './badge-name-constants';

/**
 * class is based on the asset shape
 */

export class BadgesInfo {
  static BadgeImageMapping: BadgesInfoItem[] = [
    {
      badgeTypeId: BadgeType.CompletedText,
      badgeCategoryId: BadgeCategory.ReadingPower,
      badgeName: BadgeNameConstants.Completed,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Completed_EN },
        { key: 'es', value: BadgeAsset.Badge_Completed_ES },
        { key: 'he', value: BadgeAsset.Badge_Completed_HE },
      ],
      cssClass: 'police',
    },
    {
      badgeTypeId: BadgeType.PowerTexts,
      badgeCategoryId: BadgeCategory.ReadingPower,
      badgeName: BadgeNameConstants.PowerTexts,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_PowerTexts_EN },
        { key: 'es', value: BadgeAsset.Badge_PowerTexts_ES },
      ],
      cssClass: 'trapezoid-flair',
    },
    {
      badgeTypeId: BadgeType.ReadingPower20Minutes,
      badgeCategoryId: BadgeCategory.ReadingPower,
      badgeName: BadgeNameConstants.ReadingPower,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_ReadingPower_EN },
        { key: 'es', value: BadgeAsset.Badge_ReadingPower_ES },
      ],
      cssClass: 'power',
    },
    {
      badgeTypeId: BadgeType.BeatTheClock,
      badgeCategoryId: BadgeCategory.ReadingPower,
      badgeName: BadgeNameConstants.BeatTheClock,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_BeatTheClock_EN },
        { key: 'es', value: BadgeAsset.Badge_BeatTheClock_ES },
      ],
      cssClass: 'beat',
    },
    {
      badgeTypeId: BadgeType.MillionWordsRead,
      badgeCategoryId: BadgeCategory.ReadingPower,
      badgeName: BadgeNameConstants.MillionWordsRead,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_MillionWordsRead_EN },
        { key: 'es', value: BadgeAsset.Badge_MillionWordsRead_ES },
        { key: 'he', value: BadgeAsset.Badge_MillionWordsRead_HE },
      ],
      cssClass: 'award',
    },
    {
      badgeTypeId: BadgeType.ShortResponseAboveStandard,
      badgeCategoryId: BadgeCategory.ReadingAchievement,
      badgeName: BadgeNameConstants.ShortResponseAbove,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_ShortResponseAbove_EN },
        { key: 'es', value: BadgeAsset.Badge_ShortResponseAbove_ES },
        { key: 'he', value: BadgeAsset.Badge_ShortResponseAbove_HE },
      ],
      cssClass: 'hexagon',
    },
    {
      badgeTypeId: BadgeType.ShortResponseOnStandard,
      badgeCategoryId: BadgeCategory.ReadingAchievement,
      badgeName: BadgeNameConstants.ShortResponseOn,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_ShortResponseOn_EN },
        { key: 'es', value: BadgeAsset.Badge_ShortResponseOn_ES },
        { key: 'he', value: BadgeAsset.Badge_ShortResponseOn_HE },
      ],
      cssClass: 'hexagon',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Action and Adventure',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_ActionAdventure_EN },
        { key: 'es', value: BadgeAsset.Badge_ActionAdventure_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Adventure',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Adventure_EN },
        { key: 'es', value: BadgeAsset.Badge_Adventure_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'All About',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_AllAbout_EN },
        { key: 'es', value: BadgeAsset.Badge_AllAbout_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Anthologies',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Anthologies_EN },
        { key: 'es', value: BadgeAsset.Badge_Anthologies_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Article',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Article_EN },
        { key: 'es', value: BadgeAsset.Badge_Article_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Autobiography',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Autobiography_EN },
        { key: 'es', value: BadgeAsset.Badge_Autobiography_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Biography',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Biography_EN },
        { key: 'es', value: BadgeAsset.Badge_Biography_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Classics',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Classics_EN },
        { key: 'es', value: BadgeAsset.Badge_Classics_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Drama',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Drama_EN },
        { key: 'es', value: BadgeAsset.Badge_Drama_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Essays',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Essays_EN },
        { key: 'es', value: BadgeAsset.Badge_Essays_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Fairytales',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Fairytales_EN },
        { key: 'es', value: BadgeAsset.Badge_Fairytales_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Fantasy',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Fantasy_EN },
        { key: 'es', value: BadgeAsset.Badge_Fantasy_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Folktales/Fables',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_FolktalesFables_EN },
        { key: 'es', value: BadgeAsset.Badge_FolktalesFables_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Geography',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Geography_EN },
        { key: 'es', value: BadgeAsset.Badge_Geography_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Graphic Novels',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_GraphicNovels_EN },
        { key: 'es', value: BadgeAsset.Badge_GraphicNovels_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Historical Fiction',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_HistoricalFiction_EN },
        { key: 'es', value: BadgeAsset.Badge_HistoricalFiction_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'History',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_History_EN },
        { key: 'es', value: BadgeAsset.Badge_History_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Humor',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Humor_EN },
        { key: 'es', value: BadgeAsset.Badge_Humor_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Legends/Myths',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_LegendsMyths_EN },
        { key: 'es', value: BadgeAsset.Badge_LegendsMyths_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Mathematics',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Mathematics_EN },
        { key: 'es', value: BadgeAsset.Badge_Mathematics_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Mystery',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Mystery_EN },
        { key: 'es', value: BadgeAsset.Badge_Mystery_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Picture Books',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_PictureBooks_EN },
        { key: 'es', value: BadgeAsset.Badge_PictureBooks_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Poetry',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Poetry_EN },
        { key: 'es', value: BadgeAsset.Badge_Poetry_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Realistic Fiction',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_RealisticFiction_EN },
        { key: 'es', value: BadgeAsset.Badge_RealisticFiction_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Romance',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Romance_EN },
        { key: 'es', value: BadgeAsset.Badge_Romance_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Science',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Science_EN },
        { key: 'es', value: BadgeAsset.Badge_Science_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Science Fiction',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_ScienceFiction_EN },
        { key: 'es', value: BadgeAsset.Badge_ScienceFiction_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Self Help',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_SelfHelp_EN },
        { key: 'es', value: BadgeAsset.Badge_SelfHelp_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Series',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Series_EN },
        { key: 'es', value: BadgeAsset.Badge_Series_ES },
        { key: 'he', value: BadgeAsset.Badge_Series_HE },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Short Stories',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_ShortStories_EN },
        { key: 'es', value: BadgeAsset.Badge_ShortStories_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Spanish',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Spanish_EN },
        { key: 'es', value: BadgeAsset.Badge_Spanish_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Speeches',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Speeches_EN },
        { key: 'es', value: BadgeAsset.Badge_Speeches_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Genre,
      subType: 'Thriller/Suspense',
      assets: [
        { key: 'en', value: BadgeAsset.Badge_ThrillerSuspense_EN },
        { key: 'es', value: BadgeAsset.Badge_ThrillerSuspense_ES },
      ],
      cssClass: 'circle',
    },
    {
      badgeTypeId: BadgeType.Vocabulary,
      badgeCategoryId: BadgeCategory.ReadingAchievement,
      badgeName: BadgeNameConstants.VocabClozes,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_VocabClozes_EN },
        { key: 'es', value: BadgeAsset.Badge_VocabClozes_ES },
        { key: 'he', value: BadgeAsset.Badge_VocabClozes_HE },
      ],
      cssClass: 'circle-saw',
    },
    {
      badgeTypeId: BadgeType.VocabularyGenius,
      badgeCategoryId: BadgeCategory.ReadingAchievement,
      badgeName: BadgeNameConstants.VocabGenius,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_VocabGenius_EN },
        { key: 'es', value: BadgeAsset.Badge_VocabGenius_ES },
        { key: 'he', value: BadgeAsset.Badge_VocabGenius_HE },
      ],
      cssClass: 'circle-whiz',
    },
    {
      badgeTypeId: BadgeType.WordWorkCloze,
      badgeCategoryId: BadgeCategory.ReadingAchievement,
      badgeName: BadgeNameConstants.WordWork,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_WordWork_EN },
        { key: 'es', value: BadgeAsset.Badge_WordWork_ES },
      ],
      cssClass: 'circle-saw',
    },
    {
      badgeTypeId: BadgeType.Participation,
      badgeCategoryId: BadgeCategory.ReadingAchievement,
      badgeName: BadgeNameConstants.Participation,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Participation },
      ],
      cssClass: 'competition',
    },
    {
      badgeTypeId: BadgeType.TopReader,
      badgeCategoryId: BadgeCategory.ReadingAchievement,
      badgeName: BadgeNameConstants.TopReader,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Top_Reader },
      ],
      cssClass: 'competition',
    },
    {
      badgeTypeId: BadgeType.StudentPracticedWordMasteryRoll,
      badgeCategoryId: BadgeCategory.ReadingAchievement,
      badgeName: BadgeNameConstants.StudentPracticedWordMasteryRoll,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Word_Mastery_Roll },
        { key: 'es', value: BadgeAsset.Badge_Word_Mastery_Roll },
      ],
      cssClass: 'competition',
    },
    {
      badgeTypeId: BadgeType.SuperSaver,
      badgeCategoryId: BadgeCategory.FinancialLiteracy,
      badgeName: BadgeNameConstants.SuperSaver,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_SuperSaver_EN },
        { key: 'es', value: BadgeAsset.Badge_SuperSaver_ES },
      ],
      cssClass: 'financial-literacy',
    },
    {
      badgeTypeId: BadgeType.MoneyManager,
      badgeCategoryId: BadgeCategory.FinancialLiteracy,
      badgeName: BadgeNameConstants.MoneyManager,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_MoneyManager_EN },
        { key: 'es', value: BadgeAsset.Badge_MoneyManager_ES },
      ],
      cssClass: 'financial-literacy',
    },
    {
      badgeTypeId: BadgeType.FinancialSmarts,
      badgeCategoryId: BadgeCategory.FinancialLiteracy,
      badgeName: BadgeNameConstants.FinancialSmarts,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_FinancialSmarts_EN },
        { key: 'es', value: BadgeAsset.Badge_FinancialSmarts_ES },
      ],
      cssClass: 'financial-literacy',
    },
    {
      badgeTypeId: BadgeType.SpellingBeeQuizCompletion,
      badgeCategoryId: BadgeCategory.SpellingBee,
      badgeName: BadgeNameConstants.SpellingBeeQuizCompletion,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Spelling_Bee_Quiz_Completion },
      ],
      cssClass: 'competition',
    },
    {
      badgeTypeId: BadgeType.SpellingBeeRetake,
      badgeCategoryId: BadgeCategory.SpellingBee,
      badgeName: BadgeNameConstants.SpellingBeeRetake,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Spelling_Bee_Retake },
      ],
      cssClass: 'spelling-bee-retake',
    },
    {
      badgeTypeId: BadgeType.SpellingBeeRolling,
      badgeCategoryId: BadgeCategory.SpellingBee,
      badgeName: BadgeNameConstants.SpellingBeeRolling,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Spelling_Bee_Rolling },
      ],
      cssClass: 'spelling-bee-rolling',
    },
    {
      badgeTypeId: BadgeType.MilestoneMinutesRead,
      badgeCategoryId: BadgeCategory.ReadingPower,
      badgeName: BadgeNameConstants.MilestoneMinutesRead,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Milestones_Minutes_Read },
      ],
      cssClass: 'milestone-minutes-read',
    },
    {
      badgeTypeId: BadgeType.MilestoneTextsRead,
      badgeCategoryId: BadgeCategory.ReadingPower,
      badgeName: BadgeNameConstants.MilestoneBooksRead,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Milestones_Books_Read },
      ],
      cssClass: 'milestone-books-read',
    },
    {
      badgeTypeId: BadgeType.TournamentParticipant,
      badgeCategoryId: BadgeCategory.Tournaments,
      badgeName: BadgeNameConstants.TournamentParticipant,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Tournament_Participant },
      ],
      cssClass: 'tournament-participant',
    },
    {
      badgeTypeId: BadgeType.Winner,
      badgeCategoryId: BadgeCategory.Tournaments,
      badgeName: BadgeNameConstants.Winner,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Tournament_Winner },
      ],
      cssClass: 'tournament-winner',
    },
    {
      badgeTypeId: BadgeType.RunnerUp,
      badgeCategoryId: BadgeCategory.Tournaments,
      badgeName: BadgeNameConstants.RunnerUp,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Tournament_2 },
      ],
      cssClass: 'tournament-runnerup',
    },
    {
      badgeTypeId: BadgeType.ThirdPlace,
      badgeCategoryId: BadgeCategory.Tournaments,
      badgeName: BadgeNameConstants.ThirdPlace,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Tournament_3 },
      ],
      cssClass: 'tournament-thirdplace',
    },
    {
      badgeTypeId: BadgeType.MadeTheCut,
      badgeCategoryId: BadgeCategory.Tournaments,
      badgeName: BadgeNameConstants.MadeTheCut,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Tournament_4 },
      ],
      cssClass: 'tournament-thirdplace',
    },
    {
      badgeTypeId: BadgeType.PrizeWinner,
      badgeCategoryId: BadgeCategory.Tournaments,
      badgeName: BadgeNameConstants.PrizeWinner,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Tournament_6 },
        { key: 'es', value: BadgeAsset.Badge_Tournament_6 },
      ],
      cssClass: 'tournament-prizewinner',
    },
    {
      badgeTypeId: BadgeType.RoundWinner,
      badgeCategoryId: BadgeCategory.Tournaments,
      badgeName: BadgeNameConstants.RoundWinner,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Tournament_7 },
        { key: 'es', value: BadgeAsset.Badge_Tournament_7 },
      ],
      cssClass: 'tournament-roundwinner',
    },
    {
      badgeTypeId: BadgeType.PunctuationRoll,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.PunctuationRoll,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Punctuation_Roll },
        { key: 'es', value: BadgeAsset.Badge_Punctuation_Roll },
      ],
      cssClass: 'grammar',
    },
    {
      badgeTypeId: BadgeType.PartsOfSpeechRoll,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.PartOfSpeechRoll,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Parts_Of_Speech_Roll },
        { key: 'es', value: BadgeAsset.Badge_Parts_Of_Speech_Roll },
      ],
      cssClass: 'grammar',
    },
    {
      badgeTypeId: BadgeType.SyllableRoll,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.SyllableRoll,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Syllable_Roll },
        { key: 'es', value: BadgeAsset.Badge_Syllable_Roll },
      ],
      cssClass: 'grammar',
    },
    {
      badgeTypeId: BadgeType.TeamReadingAboveTarget,
      badgeCategoryId: BadgeCategory.TeamReading,
      badgeName: BadgeNameConstants.TeamReadingAboveTarget,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Team_Reading_Above_Target },
        { key: 'es', value: BadgeAsset.Badge_Team_Reading_Above_Target },
      ],
      cssClass: 'team-reading',
    },
    {
      badgeTypeId: BadgeType.TeamReadingOnTarget,
      badgeCategoryId: BadgeCategory.TeamReading,
      badgeName: BadgeNameConstants.TeamReadingOnTarget,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Team_Reading_On_Target },
        { key: 'es', value: BadgeAsset.Badge_Team_Reading_On_Target },
      ],
      cssClass: 'team-reading',
    },
    {
      badgeTypeId: BadgeType.TeamReadingParticipation,
      badgeCategoryId: BadgeCategory.TeamReading,
      badgeName: BadgeNameConstants.TeamReadingParticipation,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Team_Reading_Participation },
        { key: 'es', value: BadgeAsset.Badge_Team_Reading_Participation },
      ],
      cssClass: 'team-reading',
    },
    {
      badgeTypeId: BadgeType.HomophonesRoll,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.HomophoneRoll,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Homophone_Roll },
        { key: 'es', value: BadgeAsset.Badge_Homophone_Roll },
      ],
      cssClass: 'grammar',
    },
    {
      badgeTypeId: BadgeType.ContractionsRoll,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.ContractionRoll,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Contraction_Roll },
        { key: 'es', value: BadgeAsset.Badge_Contraction_Roll },
      ],
      cssClass: 'grammar',
    },
    {
      badgeTypeId: BadgeType.ToBeVerbsRoll,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.ToBeVerbRoll,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_To_Be_Roll },
        { key: 'es', value: BadgeAsset.Badge_To_Be_Roll },
      ],
      cssClass: 'grammar',
    },
    {
      badgeTypeId: BadgeType.IrregularPastTense,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.IrregularPastTenseRoll,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.Badge_Irregular_Verb_Roll },
        { key: 'es', value: BadgeAsset.Badge_Irregular_Verb_Roll },
      ],
      cssClass: 'grammar',
    },

    {
      badgeTypeId: BadgeType.ContractionsBeginnerReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.ContractionsBeginnerReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.ContractionsBeginner_Mastery_EN },
        { key: 'es', value: BadgeAsset.ContractionsBeginner_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.ContractionsIntermediateReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.ContractionsIntermediateReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.ContractionsIntermediate_Mastery_EN },
        { key: 'es', value: BadgeAsset.ContractionsIntermediate_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.ContractionsAdvancedReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.ContractionsAdvancedReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.ContractionsAdvanced_Mastery_EN },
        { key: 'es', value: BadgeAsset.ContractionsAdvanced_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.ContractionsAdvancedPlusReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.ContractionsAdvancedPlusReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.ContractionsAdvancedPlus_Mastery_EN },
        { key: 'es', value: BadgeAsset.ContractionsAdvancedPlus_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.HomophonesBeginnerReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.HomophonesBeginnerReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.HomophonesBeginner_Mastery_EN },
        { key: 'es', value: BadgeAsset.HomophonesBeginner_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.HomophonesIntermediateReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.HomophonesIntermediateReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.HomophonesIntermediate_Mastery_EN },
        { key: 'es', value: BadgeAsset.HomophonesIntermediate_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.HomophonesAdvancedReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.HomophonesAdvancedReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.HomophonesAdvanced_Mastery_EN },
        { key: 'es', value: BadgeAsset.HomophonesAdvanced_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.HomophonesAdvancedPlusReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.HomophonesAdvancedPlusReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.HomophonesAdvancedPlus_Mastery_EN },
        { key: 'es', value: BadgeAsset.HomophonesAdvancedPlus_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.ToBeVerbsBeginnerReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.ToBeVerbsBeginnerReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.ToBeVerbsBeginner_Mastery_EN },
        { key: 'es', value: BadgeAsset.ToBeVerbsBeginner_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.ToBeVerbsIntermediateReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.ToBeVerbsIntermediateReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.ToBeVerbsIntermediate_Mastery_EN },
        { key: 'es', value: BadgeAsset.ToBeVerbsIntermediate_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.ToBeVerbsAdvancedReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.ToBeVerbsAdvancedReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.ToBeVerbsAdvanced_Mastery_EN },
        { key: 'es', value: BadgeAsset.ToBeVerbsAdvanced_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.ToBeVerbsAdvancedPlusReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.ToBeVerbsAdvancedPlusReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.ToBeVerbsAdvancedPlus_Mastery_EN },
        { key: 'es', value: BadgeAsset.ToBeVerbsAdvancedPlus_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.IrregularPastTenseBeginnerReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.IrregularPastTenseBeginnerReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.IrregularPastTenseBeginner_Mastery_EN },
        { key: 'es', value: BadgeAsset.IrregularPastTenseBeginner_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.IrregularPastTenseIntermediateReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.IrregularPastTenseIntermediateReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.IrregularPastTenseIntermediate_Mastery_EN },
        { key: 'es', value: BadgeAsset.IrregularPastTenseIntermediate_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.IrregularPastTenseAdvancedReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.IrregularPastTenseAdvancedReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.IrregularPastTenseAdvanced_Mastery_EN },
        { key: 'es', value: BadgeAsset.IrregularPastTenseAdvanced_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.IrregularPastTenseAdvancedPlusReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.IrregularPastTenseAdvancedPlusReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.IrregularPastTenseAdvancedPlus_Mastery_EN },
        { key: 'es', value: BadgeAsset.IrregularPastTenseAdvancedPlus_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.PunctuationBeginnerReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.PunctuationBeginnerReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.PunctuationBeginner_Mastery_EN },
        { key: 'es', value: BadgeAsset.PunctuationBeginner_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.PunctuationIntermediateReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.PunctuationIntermediateReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.PunctuationIntermediate_Mastery_EN },
        { key: 'es', value: BadgeAsset.PunctuationIntermediate_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.PunctuationAdvancedReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.PunctuationAdvancedReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.PunctuationAdvanced_Mastery_EN },
        { key: 'es', value: BadgeAsset.PunctuationAdvanced_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.PunctuationAdvancedPlusReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.PunctuationAdvancedPlusReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.PunctuationAdvancedPlus_Mastery_EN },
        { key: 'es', value: BadgeAsset.PunctuationAdvancedPlus_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.PartsOfSpeechBeginnerReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.PartsOfSpeechBeginnerReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.PartsOfSpeechBeginner_Mastery_EN },
        { key: 'es', value: BadgeAsset.PartsOfSpeechBeginner_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.PartsOfSpeechIntermediateReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.PartsOfSpeechIntermediateReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.PartsOfSpeechIntermediate_Mastery_EN },
        { key: 'es', value: BadgeAsset.PartsOfSpeechIntermediate_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.PartsOfSpeechAdvancedReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.PartsOfSpeechAdvancedReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.PartsOfSpeechAdvanced_Mastery_EN },
        { key: 'es', value: BadgeAsset.PartsOfSpeechAdvanced_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.PartsOfSpeechAdvancedPlusReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.PartsOfSpeechAdvancedPlusReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.PartsOfSpeechAdvancedPlus_Mastery_EN },
        { key: 'es', value: BadgeAsset.PartsOfSpeechAdvancedPlus_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.NumberOfSyllablesBeginnerReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.NumberOfSyllablesBeginnerReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.NumberOfSyllablesBeginner_Mastery_EN },
        { key: 'es', value: BadgeAsset.NumberOfSyllablesBeginner_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.NumberOfSyllablesIntermediateReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.NumberOfSyllablesIntermediateReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.NumberOfSyllablesIntermediate_Mastery_EN },
        { key: 'es', value: BadgeAsset.NumberOfSyllablesIntermediate_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.NumberOfSyllablesAdvancedReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.NumberOfSyllablesAdvancedReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.NumberOfSyllablesAdvanced_Mastery_EN },
        { key: 'es', value: BadgeAsset.NumberOfSyllablesAdvanced_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
    {
      badgeTypeId: BadgeType.NumberOfSyllablesAdvancedPlusReachedMastery,
      badgeCategoryId: BadgeCategory.Grammar,
      badgeName: BadgeNameConstants.NumberOfSyllablesAdvancedPlusReachedMastery,
      subType: null,
      assets: [
        { key: 'en', value: BadgeAsset.NumberOfSyllablesAdvancedPlus_Mastery_EN },
        { key: 'es', value: BadgeAsset.NumberOfSyllablesAdvancedPlus_Mastery_EN },
      ],
      cssClass: 'grammar-mastery',
    },
  ];

  static getDefaultBadge(): BadgesInfoItem {
    return {
      badgeTypeId: BadgeType.Genre,
      badgeCategoryId: BadgeCategory.Genre,
      badgeName: BadgeNameConstants.Default,
      subType: 'Default Badge',
      assets: [
        { key: 'en', value: BadgeAsset.Default_Badge },
        { key: 'es', value: BadgeAsset.Default_Badge },
      ],
      cssClass: 'circle',
      isDefaultBadge: true,
    };
  }
}
