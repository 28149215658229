import {DictionaryInterface} from "./dictionary-interface";

export class Dictionary<T> implements DictionaryInterface<T> {

    private items: { [index: string]: T } = {};

    add(key: string, value: T) {
        this.items[key] = value;
    }

    containsKey(key: string): boolean {
        return this.items.hasOwnProperty(key);
    }

    item(key: string): T {
        return this.items[key];
    }

    keys(): string[] {
        return Object.keys(this.items);
    }

    remove(key: string): T {
        let val = this.items[key];
        delete this.items[key];
        return val;
    }

    allItems(): Array<T> {
        let result = [];
        this.keys().forEach(key => {
            result.push(this.items[key]);
        });
        return result;
    }

    clear(): void {
        this.keys().forEach(key => {
            delete this.items[key];
        });
    }
}
