export class BadgeNameConstants {
    static Default = 'Badge_Default';
    static Completed = 'Badge_Completed';
    static PowerTexts = 'Badge_PowerTexts';
    static ReadingPower = 'Badge_ReadingPower';
    static BeatTheClock = 'Badge_BeatTheClock';
    static MillionWordsRead = 'Badge_MillionWordsRead';
    static Milestone = 'Badge_Milestone';
    static ShortResponseAbove = 'Badge_ShortResponseAbove';
    static ShortResponseOn = 'Badge_ShortResponseOn';
    static Genre = 'Badge_Genre';
    static VocabClozes = 'Badge_VocabClozes';
    static VocabGenius = 'Badge_VocabGenius';
    static WordWork = 'Badge_Word_Work';
    static Participation = 'Badge_Participation';
    static TopReader = 'Badge_Top_Reader';
    static SuperSaver = 'Super Saver';
    static MoneyManager = 'Money Manager';
    static FinancialSmarts = 'Financial Smarts';
    static SpellingBeeQuizCompletion = 'Spelling Bee Quiz Completion';
    static SpellingBeeRetake = 'Spelling Bee Retake';
    static SpellingBeeRolling = 'Spelling Bee Rolling';
    static MilestoneMinutesRead = 'Milestone Minutes Read';
    static MilestoneBooksRead = 'Milestone Books Read';
    static TournamentParticipant = 'Participated In Tournament';
    static Winner = 'Won In Tournament';
    static RunnerUp = 'Runner Up';
    static ThirdPlace = 'Third Place';
    static MadeTheCut = 'Made The Cut';
    static PrizeWinner = 'Prize Winner';
    static RoundWinner = 'Round Winner';
    static TeamReadingAboveTarget = 'Above Target';
    static TeamReadingOnTarget = 'On Target';
    static TeamReadingParticipation = 'Participation';
    static StudentPracticedWordMasteryRoll = 'Practiced Word Mastery Roll';
    static PunctuationRoll = 'Punctuation Mark Roll';
    static PartOfSpeechRoll = 'Part Of Speech Roll';
    static SyllableRoll = 'Syllable Roll';
    static HomophoneRoll = 'Homophone Roll';
    static ToBeVerbRoll = 'To Be Verb Roll';
    static IrregularPastTenseRoll = 'Irregular Past Tense Roll';
    static ContractionRoll = 'Contraction Roll';
    static ContractionsBeginnerReachedMastery = 'Contractions Beginner Mastery Badge';
    static ContractionsIntermediateReachedMastery = 'Contractions Intermediate Mastery Badge';
    static ContractionsAdvancedReachedMastery = 'Contractions Advanced Mastery Badge';
    static ContractionsAdvancedPlusReachedMastery = 'Contractions Advanced+ Mastery Badge';
    static HomophonesBeginnerReachedMastery = 'Homophones Beginner Mastery Badge';
    static HomophonesIntermediateReachedMastery = 'Homophones Intermediate Mastery Badge';
    static HomophonesAdvancedReachedMastery = 'Homophones Advanced Mastery Badge';
    static HomophonesAdvancedPlusReachedMastery = 'Homophones Advanced+ Mastery Badge';
    static ToBeVerbsBeginnerReachedMastery = 'ToBeVerbs Beginner Mastery Badge';
    static ToBeVerbsIntermediateReachedMastery = 'ToBeVerbs Intermediate Mastery Badge';
    static ToBeVerbsAdvancedReachedMastery = 'ToBeVerbs Advanced Mastery Badge';
    static ToBeVerbsAdvancedPlusReachedMastery = 'ToBeVerbs Advanced+ Mastery Badge';
    static IrregularPastTenseBeginnerReachedMastery = 'Irregular Past Tense Beginner Mastery Badge';
    static IrregularPastTenseIntermediateReachedMastery = 'Irregular Past Tense Intermediate Mastery Badge';
    static IrregularPastTenseAdvancedReachedMastery = 'Irregular Past Tense Advanced Mastery Badge';
    static IrregularPastTenseAdvancedPlusReachedMastery = 'Irregular Past Tense Advanced+ Mastery Badge';
    static PunctuationBeginnerReachedMastery = 'Punctuation Beginner Mastery Badge'
    static PunctuationIntermediateReachedMastery = 'Punctuation Intermediate Mastery Badge'
    static PunctuationAdvancedReachedMastery = 'Punctuation Advanced Mastery Badge'
    static PunctuationAdvancedPlusReachedMastery = 'Punctuation Advanced+ Mastery Badge'
    static PartsOfSpeechBeginnerReachedMastery = 'Parts Of Speech Beginner Mastery Badge'
    static PartsOfSpeechIntermediateReachedMastery = 'Parts Of Speech Intermediate Mastery Badge'
    static PartsOfSpeechAdvancedReachedMastery = 'Parts Of Speech Advanced Mastery Badge'
    static PartsOfSpeechAdvancedPlusReachedMastery = 'Parts Of Speech Advanced+ Mastery Badge'
    static NumberOfSyllablesBeginnerReachedMastery = 'Number Of Syllables Beginner Mastery Badge'
    static NumberOfSyllablesIntermediateReachedMastery = 'Number Of Syllables Intermediate Mastery Badge'
    static NumberOfSyllablesAdvancedReachedMastery = 'Number Of Syllables Advanced Mastery Badge'
    static NumberOfSyllablesAdvancedPlusReachedMastery = 'Number Of Syllables Advanced+ Mastery Badge'
}
