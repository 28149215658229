export enum HttpAction
{
    Get,
    Post,
    Put,
    Delete,
    Download,
    PostWithBodyResponse,
    DeleteWithBody,
    Patch,
    PostWithProgress
}
