import {ThemeDetectionService} from '../core/theme-detection.service';

export class Asset {
    static get HOME_URL(): string {
        return `./assets/images${ThemeDetectionService.RawTheme}`;
    }

    static TopBarNowReading = `${Asset.HOME_URL}/Now_Reading_Icon.svg`;
    static SideBarMenu = `${Asset.HOME_URL}/Sidebar_Menu_Icon.svg`;
    static TopBarClose = `${Asset.HOME_URL}/Close_Icon.svg`;
    static DefaultAvatar = './assets/images/Default_Avatar.svg';
    static DefaultAvatarWhite = './assets/images/Default_Avatar_White.svg';
    static SpanishFlag = './assets/images/es.svg';
    static ChangeAvatar = './assets/images/Black_Camera_Icon.svg';
    static USFlag = './assets/images/en.svg';
    static ChineseFlag = './assets/images/cn.svg';
    static FirstAccessES = './assets/images/Student_Library_FirstUse_Menu_Zoom_Spanish-LDPI.png';
    static FirstAccessEN = './assets/images/Student_Library_FirstUse_Menu_Zoom-LDPI.png';
    static PowerChallengeLogo = `${Asset.HOME_URL}/Power_Challenge_Logo.svg`;
    static PowerChallenge = './assets/images/Power_Challenge_Icon.png';
    static TextSmaller = `${Asset.HOME_URL}/Smaller_Text_Icon.svg`;
    static TextSmallerDisabled = './assets/images/Smaller_Text_Disabled.svg';
    static TextLarger = `${Asset.HOME_URL}/Larger_Text_Icon.svg`;
    static TextLargerDisabled = './assets/images/Larger_Text_Disabled.svg';
    static LargeGrayArrowLeft = './assets/images/Large_Gray_Left_Arrow.svg';
    static LargeDarkGrayArrowLeft = './assets/images/Large_Dark_Gray_Left_Arrow.svg';
    static LargeArrowLeft = `${Asset.HOME_URL}/Large_Left_Arrow.svg`;
    static LargeWhiteArrowLeft = './assets/images/Large_White_Left_Arrow.svg';
    static LargeGrayArrowRight = './assets/images/Large_Gray_Right_Arrow.svg';
    static LargeDarkGrayArrowRight = './assets/images/Large_Dark_Gray_Right_Arrow.svg';
    static LargeArrowRight = `${Asset.HOME_URL}/Large_Right_Arrow.svg`;
    static LargeWhiteArrowRight = './assets/images/Large_White_Right_Arrow.svg';
    static DotMenu = './assets/images/Book_Menu_Icon.svg';
    static Close = `${Asset.HOME_URL}/Close_Icon.svg`;
    static CircleAccept = `${Asset.HOME_URL}/Questions_Text_Menu_Icon.svg`;
    static Checkmark = `${Asset.HOME_URL}/Checkmark.svg`;
    static CheckmarkWhite = './assets/images/Checkmark_White.svg';
    static DefaultBookSmall = './assets/images/text_default_small.png';
    static DefaultBookLarge = './assets/images/text_default_large.png';
    static AddToList = `${Asset.HOME_URL}/Add_To_List_Icon.svg`;
    static AddToListGray = './assets/images/Add_Tag_Standard_Gray_Icon.svg';
    static RemoveFromList = `${Asset.HOME_URL}/Remove_From_List_Icon.svg`;
    static RemoveFromListGray = './assets/images/Smaller_Text_Disabled.svg';
    static CorrectIcon = './assets/images/Correct_Icon_White.svg';
    static IncorrectIcon = './assets/images/Incorrect_Icon.svg';
    static Loading = `${Asset.HOME_URL}/loading.svg`;
    static LoadingWhite = './assets/images/loading-white.svg';
    static LoadingGrey = './assets/images/loading-grey.svg';
    static SmallGrayCloseIcon = './assets/images/small_gray_close_icon.svg';
    static SmallGrayDownArrow = './assets/images/small_gray_down_arrow.svg';
    static SmallGrayUpArrow = './assets/images/small_gray_up_arrow.svg';
    static SmallDarkGrayDownArrow = './assets/images/small_dark_gray_down_arrow.svg';
    static SmallDarkGrayUpArrow = './assets/images/small_dark_gray_up_arrow.svg';
    static SmallBlackDownArrow = './assets/images/Small_Black_Down_Arrow.svg';
    static GrayInfoIcon = './assets/images/Info_Gray_Icon.svg';
    static GreenInfoIcon = './assets/images/Info_Green_Icon.svg';
    static BlackInfoIcon = './assets/images/info-circle-solid.svg';
    static SmallGrayArrowLeft = './assets/images/small_gray_left_arrow.svg';
    static SmallDarkGrayArrowLeft = './assets/images/small_dark_gray_left_arrow.svg';
    static SmallGrayArrowRight = './assets/images/small_gray_right_arrow.svg';
    static SmallDarkGrayArrowRight = './assets/images/small_dark_gray_right_arrow.svg';
    static SmallArrowLeft = `${Asset.HOME_URL}/small_left_arrow.svg`;
    static SmallArrowRight = `${Asset.HOME_URL}/small_right_arrow.svg`;
    static SmallWhiteArrowLeft = './assets/images/Small_White_Left_Arrow.svg';
    static LsGalleryIcon = './assets/images/galleryIcon.png';
    static GalleryFileIcon = './assets/images/galleryFileIcon.png';
    static Badge_Completed_EN = './assets/images/badge-library/Badge_Completed_en.svg';
    static ProgressIconWhite = './assets/images/Progress_Icon_White.svg';
    static ProgressIconGray = './assets/images/Progress_Icon_Gray.svg';
    static ProgressIconBlack = './assets/images/Progress_Icon_Black.svg';
    static Settings = './assets/images/Setting_Icon_White.svg';
    static SettingsBlack = './assets/images/Setting_Icon_Black.svg';
    static SettingIconGreen = './assets/images/Setting_Icon.svg';
    static PortfolioIconWhite = './assets/images/Portfolio_Icon_White.svg';
    static PortfolioIconGray = './assets/images/Portfolio_Icon_Gray.svg';
    static PortfolioIconBlack = './assets/images/Portfolio_Icon_Black.svg';
    static BadgeIconWhite = './assets/images/Badge_White_Icon.svg';
    static BadgeIconBlack = './assets/images/Badge_Black_Icon.svg';
    static DashboardIconWhite = './assets/images/Dashboard_Icon_White.svg';
    static DashboardIconBlack = './assets/images/Dashboard_Icon_Black.svg';

    static CloseIcon = './assets/images/Incorrect_Icon.svg';
    static SubmitGrayButton = './assets/images/Submit_Gray_Button.svg';
    static SubmitButton = `${Asset.HOME_URL}/Submit_Button.svg`;
    static SortIcon = './assets/images/Sort_Icon_White.svg';
    static SortIconGray = './assets/images/Sort_Icon_Gray.svg';
    static SortIconDark = './assets/images/Sort_Icon_Dark.svg';
    static ShelfLeftArrow = './assets/images/Shelf_Left_Arrow.svg';
    static PowerTextCoverIcon = `${Asset.HOME_URL}/Power_Text_Cover_Icon.svg`;
    static TextCoverCompletedIcon = `${Asset.HOME_URL}/Text_Cover_Completed_Icon.svg`;
    static TextCoverCompletedBanner = 'assets/images/Complete_Text_Banner.svg';
    static DRMIcon = './assets/images/drm.png';
    static LoadingIcon = `${Asset.HOME_URL}/loading.svg`;
    static LeftWhiteArrow = './assets/images/Left_White_Arrow.svg';
    static SmallNavArrow = `${Asset.HOME_URL}/Small_Nav_Arrow.svg`;
    static CompletedTick = `${Asset.HOME_URL}/Completed.svg`;
    static LoginLogo = './assets/images/login_image.svg';
    static Offline_Icon = `${Asset.HOME_URL}/Offline_Icon.svg`;
    static WarningIcon = './assets/images/Warning_Icon.svg';
    static PendingAssessmentIcon = `${Asset.HOME_URL}/student_question.svg`;
    static QuestionRightArrow = './assets/images/Question_Right_Arrow.svg';
    static CompletedWhiteIcon = './assets/images/Completed_White_Icon.svg';
    static PowerTextWhiteIcon = './assets/images/Power_Text_White_Icon.svg';
    static GrayRatingUp = './assets/images/Book_Rating_Up_Gray_Icon.svg';
    static RatingUp = `${Asset.HOME_URL}/Book_Rating_Up_Icon.svg`;
    static GrayRatingDown = './assets/images/Book_Rating_Down_Gray_Icon.svg';
    static RatingDown = `${Asset.HOME_URL}/Book_Rating_Down_Icon.svg`;
    static GrayThumbsUp = './assets/images/Thumbs_Up_Gray_Icon.svg';
    static ThumbsUp = `${Asset.HOME_URL}/Thumbs_Up_Icon.svg`;
    static GrayThumbsDown = './assets/images/Thumbs_Down_Gray_Icon.svg';
    static ThumbsDown = `${Asset.HOME_URL}/Thumbs_Down_Icon.svg`;
    static CompletedGrayIcon = './assets/images/Completed_Gray_Icon.svg';
    static AssignIcon = `${Asset.HOME_URL}/Assign_Icon.svg`;
    static InvalidAssignIcon = './assets/images/Assign_Gray_Icon.svg';
    static SmallPowerTextIcon = `${Asset.HOME_URL}/Small_Power_Text_Icon.svg`;
    static SelectedPowerTextIcon = './assets/images/Selected_Power_Text_Icon.svg';
    static FilterIcon = './assets/images/Filter_Icon.svg';
    static BookListIcon = './assets/images/Portfolio_Book_List_Icon.svg';
    static CheckmarkBlack = './assets/images/Checkmark_Black.svg';
    static ChatIcon = `${Asset.HOME_URL}/chat-4-32.png`;
    static ChatCloseIcon = `./assets/images/close_circle_green_icon.svg`;
    static ChatIconOff = './assets/images/chat-4-32-off.png';
    static GrayRadioButton = './assets/images/Gray_Radio_Button.svg';
    static ResetIcon = `${Asset.HOME_URL}/Reset_Icon.svg`;
    static PowerBoltIcon = `${Asset.HOME_URL}/Small_Power_Bolt.svg`;
    static ListenStopped = './assets/images/Read_Aloud_Gray_Icon.svg';
    static ListenActive = `${Asset.HOME_URL}/Read_Aloud_Icon.svg`;
    static InvalidCompletedIcon = './assets/images/Completed_Offline_Icon.svg';
    static InvalidAddToListIcon = './assets/images/Offline_Add_To_List_Icon.svg';
    static InvalidRemoveFromListIcon = './assets/images/Remove_From_List_Offline.svg';
    static SearchIcon = `${Asset.HOME_URL}/Search_Icon.svg`;
    static UpArrowBlack = './assets/images/Up_Arrow_Black.svg';
    static SmallUpArrowIcon = './assets/images/Up_Arrow.svg';
    static SmallDownArrowIcon = './assets/images/Down_Arrow.svg';
    static SmallNillBarIcon = './assets/images/Nill_Bar.svg';
    static SmalleReaderIcon = `${Asset.HOME_URL}/Small_eReader_Icon.svg`;
    static RightArrowWhite = './assets/images/small_white_right_arrow.svg';
    static AddIconWhite = './assets/images/Add_To_List_Icon_white.svg';
    static AddIconGreen = `${Asset.HOME_URL}/Plus_Icon.svg`;
    static AddIconGray = './assets/images/Plus_Gray_Icon.svg';
    static AddIconDarkGray = './assets/images/Plus_Dark_Gray_Icon.svg';
    static MinusIconDarkGray = './assets/images/Minus_Dark_Gray_Icon.svg';
    static MinusIconGreen = './assets/images/Minus_Green_Icon.svg';
    static LeaderBoardIcon = `${Asset.HOME_URL}/leaderboard_icon.svg`;
    static BadgeIcon = `${Asset.HOME_URL}/badge_icon.svg`;
    static LightsailGreenIcon = './assets/images/lightsail_bug_green_icon.svg';
    static LexileTriangle = './assets/images/Lexile_Triangle.png';
    static TrashGrayIcon = './assets/images/trash_can_gray_icon.svg';
    static SmallWhiteUpArrow = './assets/images/small_white_up_arrow.svg';
    static InfoWhiteIcon = './assets/images/Info_White_Icon.svg';
    static MoreGreenIcon = `${Asset.HOME_URL}/More_Button.svg`;
    static MoreBlackIcon = './assets/images/More_Black_Button.svg';
    static MoreGrayIcon = './assets/images/More_Gray_Button.svg';
    static LibraryIconBlack = './assets/images/Library_Icon_Black.svg';
    static LibraryIconWhite = './assets/images/Library_Icon_White.svg';
    static LibraryIconGreen = './assets/images/Library_Icon_Green.svg';
    static HomeworkIconWhite = './assets/images/Homework_Icon_White.svg';
    static HomeworkIconBlack = './assets/images/Homework_Icon_Black.svg';
    static LexileGrowthIconWhite = './assets/images/Lexile_Growth_White_Pill_Icon.svg';
    static RosterWhiteIcon = './assets/images/Roster_White_Icon.svg';
    static RosterGrayIcon = './assets/images/Roster_Gray_Icon.svg';
    static RosterBlackIcon = './assets/images/Roster_Black_Icon.svg';
    static RosterGreenIcon = `${Asset.HOME_URL}/Roster_Icon.svg`;
    static OffToggle = './assets/images/Off_Toggle.svg';
    static OnToggle = `${Asset.HOME_URL}/On_Toggle.svg`;
    static TrendsIcon = './assets/images/Trends_Icon_Off.svg';
    static TrendsActiveIcon = './assets/images/Trends_Icon_On.svg';
    static RedLockIcon = './assets/images/Red_Lock_Icon.svg';
    static RunningRecordWhiteIcon = './assets/images/Running_Record_White.svg';
    static RunningRecordBlackIcon = './assets/images/Running_Record_Black.svg';
    static TournamentsCupIcon = './assets/images/Tournaments_Cup.svg';

    static BlackBadgeBug = './assets/images/Black_Badge_Bug.svg';
    static BlackCompletionBug = './assets/images/Black_Completion_Bug.svg';
    static BlackLikeBug = './assets/images/Black_Like_Bug.svg';
    static BlackLsBug = './assets/images/Black_LS_Bug.svg';
    static BlackPtBug = './assets/images/Black_PT_Bug.svg';
    static PopUpCloseIcon = `${Asset.HOME_URL}/Popup_Close_Icon.svg`;
    static AttachmentIcon = './assets/images/Attachment_Icon.svg';
    static ToolBarMenuIcon = './assets/images/ToolBar_Menu_Icon.svg';
    static EditIcon = `${Asset.HOME_URL}/Edit_Icon.svg`;
    static EditGrayIcon = `${Asset.HOME_URL}/Edit_Gray_Icon.svg`;
    static EditIconDark = './assets/images/Edit_Icon_Dark.svg';
    static TrashGreenIcon = './assets/images/trash_can_icon.svg';
    static SortArrowGray = './assets/images/sort_gray_arrow.svg';
    static SelfServiceIcon = './assets/images/Multi_User.svg';
    static SelfServiceIconOffline = './assets/images/Multi_User_Offline.svg';
    static SelfServiceIconActive = './assets/images/Multi_User_Active.svg';
    static CalendarIcon = './assets/images/Calendar_Icon.svg';
    static StudentStatusBlack = './assets/images/Student_Status_Icon_Black.svg';
    static StudentStatusGray = './assets/images/Student_Status_Icon_Gray.svg';
    static StudentStatusWhite = './assets/images/Student_Status_Icon_White.svg';
    static Summary_Report_White = './assets/images/Summary_Report_Icon_White.svg';
    static Summary_Report_Black = './assets/images/Summary_Report_Icon_Black.svg';
    static Report_Icon = './assets/images/Reports_Icon.svg';
    static Report_Icon_Black = './assets/images/Reports_Icon_Black.svg';
    static Report_Icon_Gray = './assets/images/Reports_Icon_Gray.svg';
    static PrintIcon = `${Asset.HOME_URL}/Print_Icon.svg`;
    static PrintIconDisabled = './assets/images/Print_Icon_Disabled.svg';
    static Logo = './assets/images/logo.svg';
    static Word_Work_Active = `${Asset.HOME_URL}/Word_Work_On.svg`;
    static Word_Work = './assets/images/Word_Work_Off.svg';
    static Word_Work_Progress = './assets/images/Word_Work_Active.svg';
    static Word_Work_Next = './assets/images/Next_Arrow.svg';
    static Word_Work_Next_Disabled = './assets/images/Next_Arrow_Disabled.svg';
    static Word_Work_Prev = './assets/images/Prev_Arrow.svg';
    static Word_Work_Prev_Disabled = './assets/images/Prev_Arrow_Disabled.svg';
    static Author_Book = './assets/images/Author_Book_Off.svg';
    static Author_Book_Active = './assets/images/Author_Book_On.svg';
    static Definition_Placeholder = './assets/images/Placeholder.svg';
    static LockIcon = `${Asset.HOME_URL}/Lock_Icon.svg`;
    static Lock_Icon_Gray = './assets/images/Lock_Icon_Gray.svg';
    static LockIconGreen = `${Asset.HOME_URL}/Assignments_Lock_Icon.svg`;
    static CompletedSet = `${Asset.HOME_URL}/Correct_Icon.svg`;
    static CompletedSetActive = './assets/images/Correct_Icon_Black.svg';
    static ClozeGoalBG = './assets/images/cloze_goal_bg.svg';
    static ClozeGoalBGGreen = `${Asset.HOME_URL}/cloze_goal_main_bg.svg`;
    static LightSailIcon = './assets/images/LightSail_Logo_Icon.svg';
    static GOOGLE = './assets/images/Google_Sheets_Transparent.svg';
    static EXCEL = './assets/images/XLS_Icon.svg';
    static CSV = './assets/images/CSV_Icon.svg';
    static unSortIcon = './assets/images/unsort.svg';
    static Assignment_Menu_Icon_Drafts = './assets/images/Drafts_Gray_Icon.svg';
    static Assignment_Menu_Icon_Drafts_Black = './assets/images/Drafts_Black_Icon.svg';
    static Assignment_Menu_Icon_Assigned = './assets/images/Assigned_Gray_Icon.svg';
    static Assignment_Menu_Icon_Shared = './assets/images/Shared_Gray_Icon.svg';
    static Assignment_Menu_Icon_Units = './assets/images/Units_Gray_Icon.svg';
    static Assignment_Menu_Icon_Groups = './assets/images/Groups_Gray_Icon.svg';
    static Assignment_Menu_Icon_Gradebook = './assets/images/Gradebook_Gray_Icon.svg';
    static Assignment_Menu_Icon_Results = './assets/images/Results_Gray_Icon.svg';
    static Assignment_Menu_Icon_Rubrics = './assets/images/Rubrics_Gray_Icon.svg';
    static Assignment_Menu_Icon_Drafts_White = './assets/images/Drafts_White_Icon.svg';
    static Assignment_Menu_Icon_Assigned_White = './assets/images/Assigned_White_Icon.svg';
    static Assignment_Menu_Icon_Shared_White = './assets/images/Shared_White_Icon.svg';
    static Assignment_Menu_Icon_Units_White = './assets/images/Units_White_Icon.svg';
    static Assignment_Menu_Icon_Groups_White = './assets/images/Groups_White_Icon.svg';
    static Assignment_Menu_Icon_Gradebook_White = './assets/images/Gradebook_White_Icon.svg';
    static Assignment_Menu_Icon_Results_White = './assets/images/Results_White_Icon.svg';
    static Help_Icon = `${Asset.HOME_URL}/help.svg`;
    static Assignment_Menu_Icon_Rubrics_White = './assets/images/Rubrics_White_Icon.svg';
    static Assignment_Plus_Icon = `${Asset.HOME_URL}/Plus_Icon.svg`;
    static Assignment_Plus_Gray_Icon = './assets/images/Plus_Gray_Icon.svg';
    static Assignment_Remove_Student = `${Asset.HOME_URL}/Remove_Student_Icon.svg`;
    static Assignment_Calendar_Icon = './assets/images/Assignment_Calendar_Icon.svg';
    static Assignment_Plus_White_Icon = './assets/images/Plus_White_Icon.svg';
    static Assignment_Shadowed_Tick_Icon = `${Asset.HOME_URL}/Shadowed_Tick_Icon.svg`;
    static Remove_Icon = './assets/images/Remove_Icon.svg';
    static Assignment_Plus_White = './assets/images/Plus_White_Icon.svg';
    static Assignment_Add = `${Asset.HOME_URL}/Add_Icon.svg`;
    static Assignment_Add_Disabled = './assets/images/Add_Disabled_Icon.svg';
    static Assignment_Trash_Icon_Gray = './assets/images/Trashcan_Gray_Icon.svg';
    static Move_Icon = './assets/images/Move_Icon.svg';
    static Assignment_Trash_Icon_Dark = './assets/images/Trashcan_Dark_Icon.svg';
    static Assignments_Info_Icon = './assets/images/Information_Gray_Icon.svg';
    static Information_Green_Icon = `${Asset.HOME_URL}/Information_Icon.svg`;
    static Information_Dark_Gray_icon = `${Asset.HOME_URL}/Information_Dark_Gray_icon.svg`;
    static Information_Orange_Icon = `${Asset.HOME_URL}/Information_Orange_Icon.svg`;
    static Assignments_Remove_Icon = './assets/images/Remove_Icon.svg';
    static Assignments_Remove_Icon_Active = `${Asset.HOME_URL}/Remove_Icon_Active.svg`;
    static Assignments_Remove_Icon_Disabled = './assets/images/Remove_Icon_Disabled.svg';
    static Assignments_Remove_Icon_White = './assets/images/Remove_Icon_White.svg';
    static Assignments_Edit_Icon = './assets/images/Pencil_Gray_Icon.svg';
    static Assignments_Move_Left = './assets/images/move_left.svg';
    static Assignments_Move_Right = './assets/images/move_right.svg';
    static Assignments_Delete_Column = `${Asset.HOME_URL}/delete_column.svg`;
    static Assignments_Move_Up = './assets/images/move_up.svg';
    static Assignments_Move_Down = './assets/images/move_down.svg';
    static Assignments_Edit = `${Asset.HOME_URL}/edit.svg`;
    static Assignments_Side_Menu = './assets/images/side_menu.svg';
    static Assignments_Checkbox_Checked = `${Asset.HOME_URL}/checkbox_checked.svg`;
    static Assignments_Checkbox_Empty = './assets/images/checkbox_empty.svg';
    static Assignments_Rubric_Page = `${Asset.HOME_URL}/Rubric_Page.svg`;
    static Incomplete_Assignment_Icon = './assets/images/Incompleted_Assignment.svg';
    static Trash_Green = `${Asset.HOME_URL}/trash.svg`;
    static Thick_Right_Arrow_White_Icon = './assets/images/Thick_Right_Arrow_White_Icon.svg';
    static Assignments_Minus_Gray_Icon = './assets/images/Assignment_Minus_Grey_Icon.svg';
    static Assignments_Plus_Gray_Icon = './assets/images/Assignment_Plus_Grey_Icon.svg';
    static Assignments_Minus_White_Icon = './assets/images/Assignment_Minus_White_Icon.svg';
    static Assignments_Plus_White_Icon = './assets/images/Assignment_Plus_White_Icon.svg';
    static Rosen_LS_Light = './assets/images/Rosen_Ls_Light.svg';
    static Rosen_LS_Dark = './assets/images/Rosen_Ls_Dark.svg';
    static Rosen_LS_Mobile_Dark = './assets/images/Rosen_Lightsail_Mobile_Dark.svg';
    static Rosen_LS_Mobile_White = './assets/images/Rosen_Lightsail_Mobile_White.svg';
    static White_Bug = './assets/images/White_Bug.svg';
    static Rosen_Bug = './assets/images/Rosen_Ls_Bug.svg';
    static White_Logo = './assets/images/White_Logo.svg';
    static Badge_Round_Icon = './assets/images/Badges_Round_Icon.svg';
    static Cart_White_Icon = './assets/images/cart_white_icon.svg';
    static Like_Icon = './assets/images/Like_Icon.svg';
    static Liked_Icon = './assets/images/Liked_Icon.svg';
    static Back_Icon = `${Asset.HOME_URL}/Back_Icon.svg`;
    static Project_Icon = './assets/images/project_icon.png';
    static Assessment_Icon = `${Asset.HOME_URL}/assessment_icon.svg`;
    static Error_Close_Icon = './assets/images/Error_Close_Icon.svg';
    static FullScreenIconActive = `${Asset.HOME_URL}/Full_Screen_Icon_Active.svg`;
    static FullScreenIconDisabled = './assets/images/Full_Screen_Icon_Disabled.svg';
    static Download_Icon = `${Asset.HOME_URL}/Down_Icon.svg`;
    static Stethoscope_Icon = `${Asset.HOME_URL}/Stethoscope_Icon.svg`;
    static Crypt_Broke_Icon = './assets/images/Key_Chain_Icon.svg';
    static Student_SignUp_Icon_White = './assets/images/New_Student_Icon_White.svg';
    static Student_SignUp_Icon_Green = `${Asset.HOME_URL}/New_Student_Icon.svg`;
    static Google_Logo = './assets/images/google.svg';
    static MicrosoftLogo = './assets/images/microsoft.svg';
    static CleverLogo = './assets/images/clever.svg';
    static Gg4lLogo = './assets/images/gg4l_icon.svg';
    static ClassLinkLogo = './assets/images/classlink.svg';
    static ReloadIcon = `${Asset.HOME_URL}/Reload_Icon.svg`;
    static White_Gift_Icon = './assets/images/White_Gift_Icon.svg';
    static Gift_Icon = `${Asset.HOME_URL}/Gift_Icon.svg`;
    static Gift_Goal_New = `${Asset.HOME_URL}/Gift_Goal_New.svg`;
    static Gift_Goal_Expired = './assets/images/Gift_Goal_Expired.svg';
    static Gift_Goal_Removed = './assets/images/Gift_Goal_Removed.svg';
    static White_Fundraisers_Icon = './assets/images/White_Fundraisers_Icon.svg';
    static Black_Fundraisers_Icon = './assets/images/Black_Fundraisers_Icon.svg';
    static Cloze_Turned_Off = './assets/images/Cloze_Off.svg';

    static Reading_Option_Icon = `${Asset.HOME_URL}/Focus_Reader_Icon.svg`;
    static Reading_Option_Icon_Gray = `${Asset.HOME_URL}/Focus_Reader_Icon_Gray.svg`;
    static Reading_Focus_Auto_Read_Icon = `${Asset.HOME_URL}/Auto_Read.svg`;
    static Reading_Focus_Auto_Read_Icon_Grey = `${Asset.HOME_URL}/Auto_Read_Grey.svg`;
    static Karaoke_Mode_Stop_Grey_Icon = `${Asset.HOME_URL}/Karaoke_Mode_Stop_Grey_Icon.svg`;
    static Slider_Bar_With_Stripes = `${Asset.HOME_URL}/Slider_Bar_With_Stripes.svg`;
    static Slider_Radio = `${Asset.HOME_URL}/Slider_Radio.svg`;
    static Dark_Reader_Icon_Black = `${Asset.HOME_URL}/Dark_Reader_Icon_Black.svg`;
    static Dark_Reader_Icon_Black_Green = `${Asset.HOME_URL}/Dark_Reader_Icon_Black_Green.svg`;

    static Enhanced_Listen_Gray_Icon = `${Asset.HOME_URL}/Enhanced_Listen_Gray_Icon.svg`;
    static Enhanced_Listen_Icon = `${Asset.HOME_URL}/Enhanced_Listen_Icon.svg`;

    static ER_Enhanced_Default_Light_Icon = `${Asset.HOME_URL}/ER/Enhanced_Default_Light_Icon.svg`;
    static ER_Enhanced_Flashlight_Icon = `${Asset.HOME_URL}/ER/Enhanced_Flashlight_Icon.svg`;
    static ER_Custom_Color_Icon = `${Asset.HOME_URL}/ER/Custom_Color_Icon.svg`;
    static ER_PaletteIcon = `${Asset.HOME_URL}/ER/palette_icon.svg`;
    static ER_Settings_Icon = `${Asset.HOME_URL}/ER/settings_icon.svg`;

    static ER_back_icon = `${Asset.HOME_URL}/ER/back_icon.svg`;
    static ER_back_1_icon = `${Asset.HOME_URL}/ER/back_1_icon.svg`;
    static ER_back_3_icon = `${Asset.HOME_URL}/ER/back_3_icon.svg`;
    static ER_back_5_icon = `${Asset.HOME_URL}/ER/back_5_icon.svg`;
    static ER_follow_icon = `${Asset.HOME_URL}/ER/follow_icon.svg`;
    static ER_follow_active_icon = `${Asset.HOME_URL}/ER/follow_active_icon.svg`;
    static ER_forward_icon = `${Asset.HOME_URL}/ER/forward_icon.svg`;
    static ER_finger_touch_active_icon = `${Asset.HOME_URL}/ER/finger_touch_active_icon.svg`;
    static ER_finger_touch_icon = `${Asset.HOME_URL}/ER/finger_touch_icon.svg`;
    static ER_intensity_active_icon = `${Asset.HOME_URL}/ER/intensity_active_icon.svg`;
    static ER_intensity_icon = `${Asset.HOME_URL}/ER/intensity_icon.svg`;
    static ER_listen_icon = `${Asset.HOME_URL}/ER/listen_icon.svg`;
    static ER_listen_active_icon = `${Asset.HOME_URL}/ER/listen_active_icon.svg`;

    static Free_Read_Icon = `${Asset.HOME_URL}/Free_Read_Icon.svg`;
    static Power_Mode_Icon = `${Asset.HOME_URL}/Power_Text_Mode_Icon.svg`;
    static Euro_Logo_Light = `./assets/images/s2m/LightSail_Euroschool_Logo_Light.svg`;
    static Euro_Logo_Dark = `./assets/images/s2m/LightSail_Euroschool_Logo_Dark.svg`;
    static S2M_Logo_Light = `./assets/images/s2m/Stones_2_Milestones_Logo_Light.svg`;
    static S2M_Logo_Dark = `./assets/images/s2m/Stones_2_Milestones_Logo_Dark.svg`;
    static LS_Logo_Dark = './assets/images/LS_Logo_Dark.svg';
    static LS_Logo_Light = './assets/images/LS_Logo_Light.svg';
    static Star_Off = `./assets/images/Star_Off.svg`;
    static Star_On = `./assets/images/Star_On.svg`;
    static WishlistIcon = `./assets/images/Wishlist_Icon.svg`;
    static InboxIconWhite = './assets/images/Inbox_White_Icon.svg';
    static Microphone_Black_Icon = `${Asset.HOME_URL}/Microphone_Black_Icon.svg`;
    static Microphone_White_Icon = `${Asset.HOME_URL}/Microphone_White_Icon.svg`;
    static Play_White_Icon = `${Asset.HOME_URL}/Play_White_Icon.svg`;
    static Play_Black_Icon = `${Asset.HOME_URL}/Play_Black_Icon.svg`;
    static Play_Circle_Black_Icon = `${Asset.HOME_URL}/play-circle-regular.svg`;
    static Stop_Circle_Black_Icon = `${Asset.HOME_URL}/stop-circle-regular.svg`;
    static Stop_Circle_White_Icon = `${Asset.HOME_URL}/stop-circle-regular-white.svg`;
    static Pause_White_Icon = `${Asset.HOME_URL}/Pause_White_Icon.svg`;
    static Pause_Black_Icon = `${Asset.HOME_URL}/Pause_Black_Icon.svg`;
    static Redo_Black_Icon = `${Asset.HOME_URL}/Redo_Black_Icon.svg`;
    static Redo_White_Icon = `${Asset.HOME_URL}/Redo_White_Icon.svg`;
    static Info_white_Icon = `${Asset.HOME_URL}/Information_White_Icon.svg`;
    static Assignment_Menu_Icon_Past_Assignment = './assets/images/Past_Assignment_Icon.svg';
    static Assignment_Menu_Icon_Past_Assignment_White = './assets/images/Past_Assignment_White_Icon.svg';
    static Play_Icon = './assets/images/play_icon.svg';
    static Pause_Icon = './assets/images/pause_icon.svg';
    static Forward_Icon_grey = './assets/images/ten-seconds-fw-grey.svg';
    static Forward_Icon_green = './assets/images/ten-seconds-fw-green.svg';
    static Back_Icon_Grey = './assets/images/ten-seconds-back-grey.svg';
    static Back_Icon_Green = './assets/images/ten-seconds-back-green.svg';
    static Five_Seconds_Back_Icon_Green = './assets/images/five-seconds-back-green.svg';
    static Five_Seconds_Forward_Icon_Green = './assets/images/five-seconds-fw-green.svg';
    static Three_Seconds_Back_Icon_Grey = './assets/images/three-seconds-back-grey.svg';
    static Clipboard = `${Asset.HOME_URL}/Clipboard_Icon.svg`;
    static Clipboard_White = `./assets/images/Clipboard_Icon_White.svg`;
    static Schedule_Calendar_Icon = './assets/images/Schedule_Calendar_Icon.svg';
    static Delete_Icon = './assets/images/delete_icon.svg';
    static Redo_Icon = './assets/images/redo_icon.svg';
    static Info_Green_Icon = './assets/images/Info_Green_Icon.svg';
    static Essential_Questions_Icon = './assets/images/Essential_Questions_Icon.svg';
    static Rating_Apple = `${Asset.HOME_URL}/Rating_Apple.svg`;
    static Rating_Apple_White = './assets/images/Rating_Apple_White.svg';
    static Remote_Debugging_Icon = './assets/images/Remote_Debugging_Icon.svg';
    static Share_Unsort_Icon = './assets/images/Share_Unsort_Icon.svg';
    static Share_Ascending_Icon = './assets/images/Share_Ascending_Icon.svg';
    static Share_Descending_Icon = './assets/images/Share_Descending_Icon.svg';
    static Pencil_Edit_White = './assets/images/Pencil_Edit_White.svg';
    static Pencil_Edit_Gray = './assets/images/Pencil_Edit_Gray.svg';
    static Pencil_Edit = `${Asset.HOME_URL}/Pencil_Edit.svg`;
    static Trash_Icon_White = './assets/images/Trashcan_White_Icon.svg';
    static Trash_Icon_Gray = './assets/images/Trashcan_Dark_Gray_Icon.svg';
    static Search_Icon_White = './assets/images/Search_White.svg';
    static Voices_Icon = './assets/images/Voices_Icon.svg';
    static Voices_Icon_Black = './assets/images/Voices_Icon_Black.svg';
    static Bell_White = './assets/images/Bell_White.svg';
    static Exclamation_Triangle = `./assets/images/exclamation_triangle.svg`;
    static Progress_Bar_Circle = './assets/images/Progress_Bar_Circle.svg';
    static Text_Align_Black_Icon = './assets/images/align-justify-solid.svg';
    static Text_Align_White_Icon = './assets/images/align-justify-white-solid.svg';
    static Text_Align_Blue_Icon = './assets/images/align-justify-blue-solid.svg';
    static Plus_Black_Icon = './assets/images/Plus_Dark_Black_Icon.svg';
    static Minus_Black_Icon = './assets/images/Minus_Black_Icon.svg';
    static Add_Assessment_Gray = './assets/images/add_assessment.svg';
    static Add_Thought_Gray = './assets/images/add_thought.svg';
    static Add_Thought_Selected = './assets/images/add_thought_selected.svg';
    static Hide_Text_Gray = './assets/images/hide_text.svg';
    static Hide_Text_Selected = './assets/images/hide_text_selected.svg';
    static Read_Aloud_Voice_Gray = './assets/images/voice.svg';
    static Read_Aloud_Gray = './assets/images/read_aloud.svg';
    static Reading_Options_Gray = './assets/images/reading_options.svg';
    static Copyright_Icon = './assets/images/Copyright_Icon.svg';
    static Notification_Green_Icon = './assets/images/notification-ls-green.svg';
    static Black_Target_Icon = './assets/images/Black_Target_Icon.png';
    static Green_Target_Icon = './assets/images/Green_Target_Icon.png';

    static Edit_Teacher_Icon = './assets/images/edit_dark.svg';
    static Delete_Teacher_Icon = './assets/images/delete.svg';
    static Add_Teacher_Icon = './assets/images/add.svg';
    static Leaderboard_Icon = './assets/images/Leaderboard_Icon.png';
    static Infractions_Icon = './assets/images/infractions.svg';

    static Color_Icon = `${Asset.HOME_URL}/color_icon.svg`;
    static Color_Picker_Icon = `${Asset.HOME_URL}/color_picker.svg`;

    static Comment_Icon = `${Asset.HOME_URL}/comment_icon.svg`;
    static Font_Icon = `${Asset.HOME_URL}/font_icon.svg`;
    static Pause_Ticker_Icon = `${Asset.HOME_URL}/pause_ticker_icon.svg`;
    static Play_Ticker_Icon = `${Asset.HOME_URL}/play_ticker_icon.svg`;
    static Replay_Ticker_Icon = `${Asset.HOME_URL}/replay_ticker_icon.svg`;
    static Settings_Icon = `${Asset.HOME_URL}/settings_icon.svg`;
    static Short_Text_Icon = `${Asset.HOME_URL}/short_text_icon.svg`;
    static Speed_Icon = `${Asset.HOME_URL}/speed_icon.svg`;
    static Text_Format_Icon = `${Asset.HOME_URL}/text_format_icon.svg`;
    static Timer_Icon = `${Asset.HOME_URL}/timer_icon.svg`;
    static Volume_Icon = `${Asset.HOME_URL}/volume_icon.svg`;
    static Width_Icon = `${Asset.HOME_URL}/width_icon.svg`;
    static Switch_On = `${Asset.HOME_URL}/switch-on.svg`;
    static Switch_Off = `${Asset.HOME_URL}/switch-off.svg`;

    static Avatar_Placeholder = './assets/images/upload-avatar.svg';
    static Book_Comment_Icon = './assets/images/book-commented.svg';
    static Book_In_Review = './assets/images/in-review.svg';
    static Book_Published = './assets/images/published.svg';
    static Book_Published_Icon = './assets/images/published-book-icon.svg';
    static Book_Likes = './assets/images/book-likes.svg';
    static Book_Delete = './assets/images/book-delete.svg';
    static Book_Add = './assets/images/add-new-book-icon.svg';
    static Book_Edit = './assets/images/book-edit.svg';
    static Reply_Arrow = './assets/images/reply_arrow.svg';
    static Comment_Legend_Yellow = './assets/images/comments-legend_yellow.svg';
    static Comment_Legend_Blue = './assets/images/comments-legend_blue.svg';
    static Comment_Legend_White = './assets/images/comments-legend_white.svg';
    static Comment_Legend_Pink = './assets/images/comments-legend_pink.svg';
    static Image_Loader_Delete = './assets/images/delete_icon_red.svg';
    static About_Me = './assets/images/about-me.svg';
    static Drafts = './assets/images/drafts.svg';
    static Upload_Arrow = `${Asset.HOME_URL}/arrow-alt-circle-up-regular.svg`;

    static Custom_Cover_Template = './assets/templates/custom-cover-template.txt';
    static Student_Cover_Template = './assets/templates/student-cover-template.txt';

    static About_Author_Template = './assets/templates/about-me-template.txt';

    static CB_FollowIcon = `${Asset.HOME_URL}/cloze-box/Follow-Icon.svg`;
    static CB_FollowActiveIcon = `${Asset.HOME_URL}/cloze-box/Follow-Active-Icon.svg`;
    static CB_ListenIcon = `${Asset.HOME_URL}/cloze-box/Listen-Icon.svg`;
    static CB_ListenActiveIcon = `${Asset.HOME_URL}/cloze-box/Listen-Active-Icon.svg`;

    static Ban_Red_Icon = `${Asset.HOME_URL}/ban-solid.svg`;
    static Youtube_Icon = './assets/images/youtube_icon.svg';
    static School_Icon = './assets/images/School_Icon.svg';
    static Vertical_Dots = './assets/images/vertical_dots.svg';
    static Up_Green_Arrow = './assets/images/Up_Green_Icon.svg';
    static Down_Red_Arrow = './assets/images/Down_Red_Icon.svg';
    static ToolBar_MenuIcon_Green = './assets/images/ToolBar_Menu_Icon_Green.svg';
    static Compact_Icon = './assets/images/Compact_Icon.svg';
    static Correct_Green_Icon = './assets/images/validate-check.svg';
    static Incorrect_Red_Icon = './assets/images/validate-error.svg';
    static Notification_Red_Icon = './assets/images/Notification_Red_Icon.svg';
    static Notification_Icon = './assets/images/Notification_Icon.svg';
    static Trophy_Icon = './assets/images/Trophy_Icon_Active.svg';
    static Help_Videos_Icon = './assets/images/Help_Video.svg';
    static Copy_Book_Link = './assets/images/Copy_Book_Link.svg';
    static Focus_Skills_Data = './assets/focus_skills_data/focus_skills_data.xlsx';
    static RequestIcon = `./assets/images/request_gray_icon.svg`;
    static RequestGreenIcon = `./assets/images/request_green_icon.svg`;
    static CartIcon = `./assets/images/cart_gray_icon.svg`;
    static CartGreenIcon = `./assets/images/cart_green_icon.svg`;
    static Radio = `./assets/images/radio.svg`;
    static EmptyRadio = `./assets/images/empty_radio.svg`;
    static AssignSpellingBee = './assets/images/Assign_Spelling_Bee.svg';

    // Side Bar Icons
    static Dashboard = './assets/images/side-bar/Dashboard.svg';
    static ImportContent = './assets/images/side-bar/Import_Your_Content.svg';
    static Fluency = './assets/images/side-bar/Fluency.svg';
    static SynchronizedReading = './assets/images/side-bar/Synchronized_Reading.svg';
    static RunningRecord = './assets/images/side-bar/Running_Record.svg';
    static Homework = './assets/images/side-bar/Homework.svg';
    static PersonalizedReader = './assets/images/side-bar/Personalized_Reader.svg';
    static Library = './assets/images/side-bar/Library.svg';
    static VideoLibrary = './assets/images/side-bar/Video_Library.svg';
    static StudentWordLists = './assets/images/side-bar/Student_Word_Lists.svg';
    static WritingSolutions = './assets/images/side-bar/Writing.svg';
    static ClassProgress = './assets/images/side-bar/Class_Progress.svg';
    static Reports = './assets/images/side-bar/Reports.svg';
    static Trends = './assets/images/side-bar/Trends.svg';
    static SchoolSettings = './assets/images/side-bar/School_Settings.svg';
    static ClassSettings = './assets/images/side-bar/Class_Settings.svg';
    static StudentAuthoredBooks = './assets/images/side-bar/Student_Authored_Books.svg';
    static BuyBooks = './assets/images/side-bar/Buy_Books.svg';
    static BookStore = './assets/images/side-bar/Book_Store.svg';
    static RubricBuilder = './assets/images/side-bar/Rubric_Builder.svg';
    static Tournaments = './assets/images/side-bar/Tournaments.svg';
    static SpellingBee = './assets/images/spelling-bee.svg';
  }
