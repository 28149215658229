export class ThresholdLevelColorConstants {
    static NoScore = '#999999';
    static AboveStandard = '#419bcb';
    static OnStandard = '#52B64D';
    static ApproachingStandard = '#CCCC33';
    static BelowStandard = '#F7681E';
    static Predicted = '#BDBDBD';
    static PredictedLine = '#999999';
    static NoData = '#666666';
    static Unknown = '#767676';
    static BaseWordWork = '#999999';
}
