export class GlobalTableNames {
    static LoginCredentials: string = 'LoginCredentials';
    static Student: string = 'Student';
    static Class: string = 'Class';
    static StudentClass: string = 'StudentClass';
    static Teacher: string = 'Teacher';
    static ApiQueue: string = 'MsgQueue';
    static ApiLog: string = 'MsgLog';
    static School: string = 'School';
    static SchoolWeek: string = 'SchoolWeek';
    static Vacations: string = 'Vacations';
    static UserMetaData: string = 'UserMetaData';
    static UserPowerText: string = 'userPowerText';
    static UserCompletedTexts: string = 'userCompletedTexts';
    static TeacherClass: string = 'TeacherClass';
    static BorrowedBook: string = 'BorrowedBook';
    static BookMetaData: string = 'BookMetaData';
    static BookCoverQueue: string = 'BookCoverQueue';
    static BookDetailQueue: string = 'BookDetailQueue';
    static systemInfo: string = 'SysInfo';
    static UserSession: string = 'UserSession';
    static ReadingTag: string = 'ReadingTag';
    static GradeReadingTag: string = 'GradeReadingTag';
    static CommonCoreStandard: string = 'CommonCoreStandard';
    static CommonCoreSubStandard: string = 'CommonCoreSubStandard';
    static CommonCoreGradeSubStandard: string = 'CommonCoreGradeSubStandard';
    static BookCache: string = 'BookCache';
    static AssessmentAnswer: string = 'AssessmentAnswer';
    static Thought: string = 'Thought';
    static ThoughtComment: string = 'ThoughtComment';
    static SchoolLibraryIds: string = 'SchoolLibraryIds';
    static BookContent: string = 'rc';
    static StudentSettings: string = 'StudentSettings';
    static StatusColumns: string = 'StatusColumns';
    static SchoolMetaData: string = 'SchoolMetaData';
    static Rubric: string = 'Rubric';
    static AcademicYearSchoolClasses: string = 'AcademicYearSchoolClasses';
    static ClassSettings: string = 'ClassSettings';
    static AccountSettings: string = 'AccountSettings';
    static StudentLexileList: string = 'StudentLexileList';
    static EventQueue = 'EventQueue';
    static PowerChallenge = 'PowerChallenge';
}
