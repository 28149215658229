export enum SettingsPanel {
  PersonalInformation = 'personal-info',
  License = 'license-info',
  StrugglingReader = 'struggling-reader',
  Tournaments = 'tournaments',
  Bio = 'child-bio',
  PowerChallenge = 'power-challenge',
  ContentAccess = 'content-access',
  ChildSafetyControls = 'child-safety-controls',
  Clozes = 'clozes',
  ReadingModes = 'reading-modes',
  AudioBooks = 'audio-books',
  Goals = 'gift-and-goals',
  FeaturesAccess = 'feature-switcher',
  ChatsAccess = 'chats-access',
  AuthenticWriting = 'authentic-writing',
  SpellingBee = 'spelling-bee',
  CameraAccess = 'camera-access',
  BuyBooks = 'buy-books',
  HiddenBooks = 'hidden-books',
  HiddenVideos = 'hidden-videos',
  TournamentHistory = 'tournament-history',
}
