export class AppStateConstants {
    static online = 'online';
    static connected = 'connected';
    static browserOK = 'browser';
    static IsSafari = 'safari';
    static lightSailServerURL = 'lightSailServerURL';
    static signalRUrl = 'signalRUrl';
    static signalRConnected = 'signalRConnected';
    static preCacheData = 'preCacheData';
    static classSelected = 'classSelected';
    static apiVersion = 'apiVersion';
    static PublicOnly = 'publicOnly';
    static storageOk = 'storageOk';
    static extraConnected = 'extraConnected';
    static dictionaryApi = 'dictionaryAPI';
    static demoFeaturesActive = 'demoFeaturesActive';
    static supportStreaming = 'supportStreaming';
    static streamSources = 'streamSources';
    static lastSpeedTestTime = 'lastSpeedTestTime';
    static lastSpeedTestResult = 'lastSpeedTestResult';
    static useChinese = 'useChinese';
    static contentBuilderOnly = 'contentBuilderOnly';
    static rosenLibrary = 'rosenLibrary';
    static rocketChatApiUrl = 'rocketChatApiUrl';
    static awsTranscribeAccessId = 'awsTranscribeAccessId';
    static awsTranscribeSecretKey = 'awsTranscribeSecretKey';
    static awsRegion = 'awsRegion';
    static chinaRegion = 'chinaRegion';
}
