import {Theme, Partner} from './enums';

export class ThemeDetectionService {
    private static _theme: Theme;
    private static _partner: Partner;
    private static _rawTheme: string;
    private static _themeStyle: string;

    static get Theme(): Theme {
        if (ThemeDetectionService._theme === undefined) {
            ThemeDetectionService.detect();
        }

        return ThemeDetectionService._theme;
    }

    static get Partner(): Partner {
        if (ThemeDetectionService._partner === undefined) {
            ThemeDetectionService.detect();
        }

        return ThemeDetectionService._partner;
    }

    static get RawTheme(): string {
        if (ThemeDetectionService._rawTheme === undefined) {
            ThemeDetectionService.detect();
        }

        return ThemeDetectionService._rawTheme;
    }

    static get ThemeStyle(): string {
        if (ThemeDetectionService._themeStyle === undefined) {
            ThemeDetectionService.detect();
        }

        return ThemeDetectionService._themeStyle;
    }
    static get isParentPortal(): boolean {
        return window.location.href.toLowerCase().toLowerCase().indexOf('parent') !== -1;
    }

    private static detect(): void {
        const isEuro = window.location.href.toLowerCase().indexOf('euro-') !== -1;
        const isS2m = window.location.href.toLowerCase().indexOf('s2m-') !== -1;
        const isRosen = window.location.href.toLowerCase().indexOf('://rosen.') !== -1;
        const isDemo = window.location.href.toLowerCase().indexOf('://sales.') !== -1;

        ThemeDetectionService._theme = isEuro && isS2m ? Theme.Blue : Theme.Default;
        ThemeDetectionService._rawTheme = isS2m && isEuro ? '/s2m' : '';
        ThemeDetectionService._themeStyle = ThemeDetectionService._theme === Theme.Blue ? ' s2m-theme' : '';
        ThemeDetectionService._partner = 
                isEuro  ?       Partner.Euro 
            :   isDemo  ?       Partner.Demo
            :   isRosen ?       Partner.Rosen
            :   isS2m   ?       Partner.S2M
            :                   Partner.None;
    }
}
